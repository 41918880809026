import React from 'react'
import { makeStyles } from 'tss-react/mui'
import useMediaQuery from '@mui/material/useMediaQuery'
import Skeleton from 'react-loading-skeleton'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import presentationStyle from 'assets/jss/material-kit-pro-react/views/presentationStyle'
import { Accordion, Link, AccordionSummary, AccordionDetails, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import isURL from 'validator/lib/isURL'
import './style.css'
import imbfoodtrust from 'assets/img/ibm-foodtrust-logo.svg'

const useStyles = makeStyles()(presentationStyle)

const AttributesLotGrid = (props) => {
  const { classes } = useStyles()
  const { t } = useTranslation('attributesLot')
  //   const { state } = useContext(AppContext)
  const { lightLot } = props
  const isSmall = useMediaQuery('(max-width: 600px)')
  console.log('lighLot', lightLot)

  const handleValue = (name, value) => {
    if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/.test(value)) {
      return moment(value).format('DD/MM/YYYY')
    } else if (isURL(value)) {
      return lightLot.organizationId === '5ad4a56c-9326-43e5-a236-f1a99f1edf66'
        ? <img className={classes.blockchainIcon} alt={'IBM logo'}
          src={`${imbfoodtrust}`}
        />

        : <Link href={value} target='_blank'>
          {t('moreInfo')}
        </Link>
    } else {
      return value
    }
  }

  return <GridContainer className={!isSmall ? classes.contentContainer : null} style={{ width: '100%', background: 'white' }}>
    {
      lightLot && lightLot.attributes && lightLot.attributes.length > 0
        ? isSmall
          ? <Accordion className="accordionRoot">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography style={{ textAlign: 'center', width: '100%', paddingLeft: '10%' }} className="fredoka">{t('title')}</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ display: 'block' }}>
              {lightLot.attributes.map(
                (attribute, index) =>
                  <GridItem key={`${attribute.name}${attribute.value}`}
                    xs={12} md={6} xl={4}
                    className={classes.attributeGrid}
                    style={isSmall && index !== (lightLot.attributes.length - 1) ? { paddingBottom: '0px' } : {}}>
                    <div className="raleway inputList">
                      <b className={classes.fredoka}>{attribute.name}</b>
                      <div>{handleValue(attribute.name, attribute.value)}</div>
                    </div>

                  </GridItem>
              )}
            </AccordionDetails>
          </Accordion>
          : lightLot.attributes.map(
            (attribute, index) =>
              <GridItem key={`${attribute.name}${attribute.value}`}
                xs={12} md={6} xl={4}
                className={classes.attributeGrid}
                style={isSmall && index !== (lightLot.attributes.length - 1) ? { paddingBottom: '0px' } : {}}>
                <b className={classes.fredoka}>{attribute.name || <Skeleton duration={0.7} />}</b>
                <div>{handleValue(attribute.name, attribute.value) || <Skeleton duration={0.7} count={3}/>}</div>
              </GridItem>)
        : null
    }
  </GridContainer>
}
export default AttributesLotGrid
