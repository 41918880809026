import React, { useState } from 'react'
import { MapContainer, TileLayer, Marker, Popup, Polyline } from 'react-leaflet'
import Button from 'components/CustomButtons/Button'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()(theme => ({
  changeTile: {
    position: 'absolute',
    top: theme.spacing(1),
    left: '70px',
    padding: theme.spacing(1),
    zIndex: '400',
    '@media(max-width: 600px)': {
      left: 'unset',
      right: theme.spacing(1)
    }
  }
}))

function Map (props) {
  const { classes } = useStyles()
  // different tile urls objects
  const urlTiles = {
    satellite: 'http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
    openstreetmap: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
  }

  // current tile. Default is openstreetmap
  const [tileSet, setTileSet] = useState(urlTiles.openstreetmap)

  // function to toggle map visualization
  const toggleTileSet = () => {
    tileSet === urlTiles.satellite ? setTileSet(urlTiles.openstreetmap) : setTileSet(urlTiles.satellite)
  }

  return (
    <MapContainer center={[props.lat, props.lng]} doubleClickZoom={false} scrollWheelZoom={false} touchZoom={true} zoom={props.zoom ? props.zoom : 17} style={props.isHeader ? { height: '100%' } : { height: '100%' }} boxZoom={false}>
      <TileLayer attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' url={tileSet} />
      {props.positions ? props.positions.map((position, index) => <Marker key={`marker-${index}`} position={[position[0], position[1]]}>
        <Popup>
          <a style={{ textDecoration: 'none' }} href={`https://www.google.com/maps/search/?api=1&query=${position[0]},${position[1]}`} target="_blank" rel="noopener noreferrer nofollow">Apri la mappa</a>
        </Popup>
      </Marker>) : <Marker position={[props.lat, props.lng]}>
        <Popup>
          <a style={{ textDecoration: 'none' }} href={`https://www.google.com/maps/search/?api=1&query=${props.lat},${props.lng}`} target="_blank" rel="noopener noreferrer nofollow">Apri la mappa</a>
        </Popup>
      </Marker>}
      <Button onClick={() => toggleTileSet()} color='ctaButton' className={classes.changeTile}>{tileSet === urlTiles.openstreetmap ? 'Vista Satellitare' : 'Vista Stradale'}</Button>
      {props.positions ? <Polyline color="#09a963" positions={props.positions} /> : ''}
    </MapContainer>

  )
}

export default Map
