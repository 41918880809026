import React, { useContext } from 'react'
import { makeStyles } from 'tss-react/mui'
import presentationStyle from 'assets/jss/material-kit-pro-react/views/presentationStyle.js'
import { useTranslation } from 'react-i18next'
import { AppContext } from '../../context/AppState'
import organizationToRules from 'organizationToRules'

import { hideOrShowProperty } from 'utils/utils'
import { Box } from '@mui/material'
import Button from 'components/CustomButtons/Button.js'

const useStyles = makeStyles()(presentationStyle)

const MenuDesktop = (props) => {
  const { state, dispatch } = useContext(AppContext)

  const { sectionActive, discover, company } = state

  // funzione che cambia la sezione visualizzata
  const changeSection = (event, section) => {
    dispatch({
      type: 'SET_MENU_SECTION',
      payload: section
    })
  }

  const { classes } = useStyles()
  const { t } = useTranslation('menu')
  return <Box component="div" className={classes.menuContainer}>
    <Box component="div" className={classes.mainMenu}>
      <Button onClick={() => changeSection(null, 1)} className={classes.menuButtonPadding} color={sectionActive !== 1 ? 'transparent' : 'productButton'}>
        <div className={classes.sectionButton}>
          <div style={{ marginBottom: '18px' }}>
            <i style={{ fontSize: '1.4rem', marginRight: '2px', color: '#3C4858' }} className="fas fa-search" />
          </div>
          <div style={{ color: '#3C4858' }}>{t('specifications')}</div>
        </div>
      </Button>
      {!organizationToRules[company.organizationId] || (organizationToRules[company.organizationId] && !organizationToRules[company.organizationId].hideCompany)
        ? <Button onClick={() => changeSection(null, 2)} className={classes.menuButtonPadding} color={sectionActive !== 2 ? 'transparent' : 'companyButton'}>
          <div className={classes.sectionButton}>
            <div style={{ marginBottom: '18px' }}>
              <i style={{ fontSize: '1.4rem', marginRight: '2px', color: '#3C4858' }} className="fas fa-industry" />
            </div>
            <div style={{ color: '#3C4858' }}>{t('company')}</div>
          </div>
        </Button>
        : null }
      {state.product.certifications != null
        ? (hideOrShowProperty(discover, 'certifications', 'product') && state.product.certifications.length > 0 &&
          (<Button onClick={() => changeSection(null, 3)} className={classes.menuButtonPadding} color={sectionActive !== 3 ? 'transparent' : 'allergenesButton'}>
            <div className={classes.sectionButton}>
              <div style={{ marginBottom: '18px' }}>
                <i style={{ fontSize: '1.4rem', marginRight: '2px', color: '#3C4858' }} className="fas fa-fingerprint" />
              </div>
              <div style={{ color: '#3C4858' }}>{t('certifications')}</div>
            </div>
          </Button>))
        : null}
      {hideOrShowProperty(discover, 'allergenes', 'product') && state.product.showAllergenes && (<Button onClick={() => changeSection(null, 4)} className={classes.menuButtonPadding} color={sectionActive !== 4 ? 'transparent' : 'allergenesButton'}>
        <div className={classes.sectionButton}>
          <div style={{ marginBottom: '18px' }}>
            <i style={{ fontSize: '1.4rem', marginRight: '2px', color: '#3C4858' }} className="fas fa-utensils" />
          </div>
          <div style={{ color: '#3C4858' }}>{t('allergenes')}</div>
        </div>
      </Button>)}
      {(hideOrShowProperty(discover, 'traceability', 'product') || (hideOrShowProperty(discover, 'traceability', 'lot'))) && <Button onClick={() => changeSection(null, 5)} className={classes.menuButtonPadding} color={sectionActive !== 5 ? 'transparent' : 'traceabilityButton'}>
        <div className={classes.sectionButton}>
          <div style={{ marginBottom: '18px' }}>
            <i style={{ fontSize: '1.4rem', marginRight: '2px', color: '#3C4858' }} className="far fa-calendar-alt" />
          </div>
          <div style={{ color: '#3C4858' }}>{t('traceability')}</div>
        </div>
      </Button>}
      {hideOrShowProperty(discover, 'waste', 'product') && state.product.waste != null
        ? (state.product.waste.length > 0 &&
          (<Button onClick={() => changeSection(null, 6)} className={classes.menuButtonPadding} color={sectionActive !== 6 ? 'transparent' : 'productButton'}>
            <div className={classes.sectionButton}>
              <div style={{ marginBottom: '18px' }}>
                <i style={{ fontSize: '1.4rem', marginRight: '2px', color: '#3C4858' }} className="fas fa-recycle" />
              </div>
              <div style={{ color: '#3C4858' }}>{t('waste')}</div>
            </div>
          </Button>))
        : null}
    </Box>
  </Box>
}

export default MenuDesktop
