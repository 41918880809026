import React, { useContext, useState, useEffect } from 'react'
import { makeStyles } from 'tss-react/mui'
import presentationStyle from 'assets/jss/material-kit-pro-react/views/presentationStyle'
import { Box, Typography, Link, Grid } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTranslation } from 'react-i18next'
import { getFacilities } from 'actions/AppActions'
import RoomIcon from '@mui/icons-material/Room'
// import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Map from 'components/Map/Map'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Card from 'components/Card/Card'
import CardHeader from 'components/Card/CardHeader'
import CardBody from 'components/Card/CardBody'
import Button from 'components/CustomButtons/Button'
import { hideOrShowProperty } from 'utils/utils'
import { AppContext } from '../../context/AppState'

const useStyles = makeStyles()(presentationStyle)

function SingleSource ({ src }) {
  return (
    <div>
      <div style={{ position: 'relative', display: 'inline-block' }}>
        <img alt="location-img" style={{ maxWidth: '100%', width: 'auto', height: 'auto' }} src={src}></img>
      </div>
    </div>
  )
}

const inputsOutputsTableDoby = (track, inputs = [], outputs = []) => {
  const array = [...Array(!outputs ? inputs.length : inputs.length >= outputs.length ? inputs.length : outputs.length)]
  const finalArray = []
  array.forEach((el, i) => {
    const newLine = []
    if (inputs && inputs.length > 0 && inputs[i] && track.inputs[i]) {
      newLine[0] = <Grid key={`${track.name}-input-grid-${i.toString()}`}><b>{`${inputs[i].inputData.name}`}</b><br></br>
      </Grid>
    } else {
      newLine[0] = ''
    }
    if (outputs && outputs[i] && track.outputs[i]) {
      newLine[1] = <Grid key={`${track.name}-output-grid-${i.toString()}`}><b key={i.toString()}>{`${outputs[i].outputData.name} `}</b><br></br>
      </Grid>
    } else {
      newLine[1] = ''
    }

    finalArray.push(newLine)
  })

  return finalArray.map((elem, i) => {
    return (
      <div key={`${track.name}-elem-div-${i.toString()}`}>
        <div key={`${track.name}-input-div-${i.toString()}`} className="raleway inputList">
          {elem[0]}
        </div>
        {outputs && outputs.length > 0 &&
            <div key={`${track.name}-output-div-${i.toString()}`} className="raleway inputList">
              {elem[1]}
            </div>
        }
      </div>
    )
  })
}

const LightLotTraceabilityMobileCard = (props) => {
  const { classes } = useStyles()
  const isSmall = useMediaQuery('(max-width: 600px)')
  const { t } = useTranslation('traceabilityCard')

  // eslint-disable-next-line no-unused-vars
  const [textSize, setTextSize] = useState('default')

  const { track, index, organizationId } = props
  // stato globale dell'app estrapolato dal context
  const { state, dispatch } = useContext(AppContext)
  const { lightLot, discover } = state

  // eslint-disable-next-line no-unused-vars
  const [facilities, setFacilities] = useState([])
  const [facilityUiidNameMap, setFacilityUiidNameMap] = useState({})

  // funzione che apre modal documenti
  const handleOpenTrackDoc = (track) => {
    dispatch({
      type: 'SET_TRACK_DOC',
      payload: track
    })
    dispatch({
      type: 'SET_OPEN_TRACK_DOC',
      payload: true
    })
  }

  useEffect(() => {
    const trackTitle = track.name
    if (trackTitle.length > 10) {
      setTextSize('small')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // funzione che apre modal descrizione traccia
  const handleOpenTrackDesc = (description, name, imgUrl) => {
    dispatch({
      type: 'SET_TRACK_DESC',
      payload: { description, name, imgUrl }
    })
    dispatch({
      type: 'SET_OPEN_TRACK_DESC',
      payload: true
    })
  }
  const facilityParams = {
    limit: Number.MAX_SAFE_INTEGER,
    fields: 'name,gln,uuid,geolocation'
  }
  // NOTE: useEffect utile per recuperare le facilies che dovrò mostrare nel tabela
  useEffect(() => {
    // eslint-disable-next-line no-inner-declarations
    async function fetchFacilities () {
      const facilities = await getFacilities(organizationId, facilityParams)
      setFacilities(facilities)
      const mapFac = {}
      facilities.forEach(x => {
        mapFac[x.uuid] = x
      })
      setFacilityUiidNameMap(mapFac)
    }
    fetchFacilities()
  }, [])

  // funzione che mostra la mappa di una card nella tracciabilità
  const toggleMap = (cardIndex) => {
    const lightL = Object.assign({}, lightLot)
    const traceability = lightL.traceability
    const { activeSection, ...track } = traceability[cardIndex]
    traceability.splice(cardIndex, 1, { activeSection: { card: cardIndex, showMap: !activeSection.showMap }, ...track })
    lightL.traceability = traceability
    dispatch({
      type: 'SET_LIGHT_LOT',
      payload: lightL
    })
  }

  // funzione che torna il bottone di mostra di più per le descrizioni troncate
  const addShowMoreButton = (id, description, name, imgUrl) => {
    const elem = document.getElementById(id)
    if (elem) {
      if (elem.scrollWidth > elem.clientWidth) {
        return (
          <Box display="flex" justifyContent="center" alignItems="center" style={{ width: '100%' }}>
            <Link onClick={() => handleOpenTrackDesc(description, name, imgUrl)} style={{ cursor: 'pointer', color: ' #3C4858' }}>
              <AddCircleOutlineIcon />
            </Link>
          </Box>
        )
      } else {
        return ''
      }
    }
  }

  return <GridItem md={6} xs={12} style={isSmall ? { paddingLeft: 0, paddingRight: 0 } : {}}>
    <Card style={{ marginBottom: '40px', paddingBottom: 14, height: 'auto', flexDirection: 'column', display: 'flex' }}>
      <CardHeader>
        {/* FASE */}
        <GridContainer style={{ width: '100%', margin: '10px 0', flexDirection: 'column' }} className="phasesContainer" alignItems="center">
          {/* HEADER FASE */}
          <GridItem xs={12} md={12} style={{ textAlign: 'center', padding: 0 }}>
            <span style={{ textAlign: 'center' }} className={`${classes.fredoka} ${classes.cardTitleSmall} ${classes.nameTraceabilityMobile}`}>{index + 1}</span>
          </GridItem>
          <GridItem xs={12} md={12}>
            {/* {textSize === 'default'
              ? <h4 className={classes.phasesName} style={{ fontSize: '1.7em' }}>{track.name}</h4>
              : <h4 className={classes.phasesName} style={{ fontSize: '1.4em' }}>{track.name}</h4>
            } */}
            <h4 className={classes.phasesName} style={{ fontSize: '1.3em', textAlign: 'center' }}>{track.name}</h4>
          </GridItem>
          {/* MAPPE */}
          {/* NOTE: nonostante locationTo e locationFrom siano array, l'accesso al loro contenuto può esser fatto così e non direttamente fornendo l'indice */}
          <GridItem style={{ width: '100%', padding: '0', minHeight: 150 }} xs={12}>
            {hideOrShowProperty(discover, 'allLocations', 'lot') && track.location && facilityUiidNameMap[track.location] && track.activeSection.showMap
              ? <div className={`${classes.mapContainer} noImage`}>
                 <Map isHeader={true} lat={facilityUiidNameMap[track.location].geolocation.lat} lng={facilityUiidNameMap[track.location].geolocation.lng} />
              </div>
              : track.locationFrom && facilityUiidNameMap[track.locationFrom] &&
                  track.locationTo && facilityUiidNameMap[track.locationTo] && track.activeSection.showMap
                ? <div className={`${classes.mapContainer} noImage`}>
                  <Map isHeader={true} zoom={14} positions={[[facilityUiidNameMap[track.locationFrom[0]].geolocation.lat, facilityUiidNameMap[track.locationFrom[0]].geolocation.lng], [facilityUiidNameMap[track.locationTo[0]].geolocation.lat, facilityUiidNameMap[track.locationTo[0]].geolocation.lng]]} lat={facilityUiidNameMap[track.locationTo[0]].geolocation.lat} lng={facilityUiidNameMap[track.locationTo[0]].geolocation.lng} />                
                  </div>
                : <div className={classes.imgContainer}>
                  {track.media.length > 0
                    ? <SingleSource key={track.media[0].cropped} src={track.media[0].cropped} />
                    : null}
                </div>
            }
            {/* <div className={classes.imgContainer} style={track.media.length > 0 ? { minHeight: 150, backgroundImage: `url('${track.media[0].cropped}')` } : {}}>
            </div> */}
          </GridItem>
          {hideOrShowProperty(discover, 'allLocations', 'lot') && <GridItem style={{ marginTop: '-50px', padding: 0, width: '100%', textAlign: 'center' }} xs={12}>
            <Button
              style={{ backgroundColor: '#00A4AE', zIndex: 1000 }}
              className="mapButton"
              round
              justIcon
              onClick={() => toggleMap(index)}
            >
              <i title={track.activeSection.showMap ? t('showImage') : t('showMap')} className={track.activeSection.showMap ? 'fas fa-image' : 'fas fa-map-marker-alt'} />
            </Button>
          </GridItem>
          }
        </GridContainer>
      </CardHeader>
      <CardBody className={classes.cardBodyContainer} style={isSmall ? { padding: '0 10px' } : {}}>
        {/* LUOGO */}
        {hideOrShowProperty(discover, 'allLocations', 'lot') && track.location
          ? <GridContainer style={{ width: '100%', margin: '10px 0', display: 'flex', justifyContent: 'center' }}>
            <h6 style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <RoomIcon style={{ marginBottom: 10, color: '#3C4858' }} />
              <b>{facilityUiidNameMap[track.location] && facilityUiidNameMap[track.location].name}</b>
            </h6>
          </GridContainer>
          : track.locationFrom && Array.isArray(track.locationFrom) && track.locationFrom.length > 0 && track.locationTo && Array.isArray(track.locationTo) && track.locationTo.length > 0
            ? <>
              {hideOrShowProperty(discover, 'allLocations', 'lot') && <GridContainer style={{ display: 'block', width: '100%', margin: '10px 0' }}>
                <div style={{ width: '50%', float: 'left' }}>
                  <h6 style={{
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: '0 10px'
                  }}
                  >
                    <RoomIcon style={{ marginBottom: 10, color: '#3C4858' }} />
                    {t('from')} <br></br>  {facilityUiidNameMap[track.locationFrom[0]] && facilityUiidNameMap[track.locationFrom[0]].name}
                  </h6>
                </div>
                <div style={{ width: '50%', float: 'left' }}>
                  <h6 style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '0 10px' }}>
                    <RoomIcon style={{ marginBottom: 10, color: '#3C4858' }} />
                    {t('to')} <br></br>{facilityUiidNameMap[track.locationTo[0]] && facilityUiidNameMap[track.locationTo[0]].name}
                  </h6>
                </div>
              </GridContainer>
              }
              <GridContainer style={{ width: '100%' }}>
                <div className="lineContainer">
                  <span className="dot first"></span>
                  <span className="line"></span>
                  <span className="dot last"></span>
                </div>
              </GridContainer>
            </>
            : ''
        }

        {/* MATERIE */}
        <GridContainer style={{ width: '100%' }}>
          {hideOrShowProperty(discover, 'allInputs', 'lot') && <Accordion defaultExpanded='true' className="accordionRoot">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography style={{ textAlign: 'center', width: '100%', paddingLeft: '10%' }} className="fredoka">{t('usedMaterials')}</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ display: 'block' }}>
              {inputsOutputsTableDoby(track, track.inputs)}
            </AccordionDetails>
          </Accordion>
          }
          {track.outputs && track.outputs.length > 0 &&
          hideOrShowProperty(discover, 'allOutputs', 'lot') && <Accordion defaultExpanded='true' className="accordionRoot">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography style={{ textAlign: 'center', width: '100%', paddingLeft: '10%' }} className="fredoka">{t('producedMaterials')}</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ display: 'block' }}>
              {inputsOutputsTableDoby(track, [], track.outputs)}
            </AccordionDetails>
          </Accordion>
          }

        </GridContainer>

        {/* DESCRZIONE */}
        <GridContainer style={{ width: '100%', marginTop: 20 }}>
          <Typography id={`${track.name}-${index}`} variant="body1" noWrap={true} style={{ marginTop: 0, marginBottom: 8, width: '100%', textAlign: 'center' }} className={classes.cardDescription}>
            {track.description}
          </Typography>
          {addShowMoreButton(`${track.name}-${index}`, track.description, track.name, track.media.length > 0 ? track.media[0].cropped : null)}
        </GridContainer>

        {/* DOCUMENTI */}
        {hideOrShowProperty(discover, 'allDocuments', 'lot') && <GridContainer direction="row" justify="flex-end" alignItems="flex-end" style={{ marginTop: 10 }}>
          <GridItem>
            <Button
              color="ctaButton"
              className="attachmentButton"
              disabled={track.documents.length === 0}
              onClick={() => handleOpenTrackDoc(track)}
              style={{ display: track.documents.length === 0 ? 'none' : 'block' }}
            >
              {track.documents.length === 0 ? t('noAttachments') : t('showAttachments')}
            </Button>
          </GridItem>
        </GridContainer>
        }
      </CardBody>
    </Card>
  </GridItem>
}

export default LightLotTraceabilityMobileCard
