const organizationToRules = {
  '5ad4a56c-9326-43e5-a236-f1a99f1edf66': {
    tableSearchLot: false
  },
  '0a098fd4-61bd-4dd6-8550-6d7b37b9d1a6': {
    virtualAssistant: true,
    hideCompany: true,
    footer: true
  }
}
export default organizationToRules
