import React from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'

import MenuDesktop from './MenuDesktop'
import MenuMobile from './MenuMobile'

const Menu = () => {
  const isSmall = useMediaQuery('(max-width: 600px)')

  return isSmall
    ? <MenuMobile/> : <MenuDesktop />
}

export default Menu
