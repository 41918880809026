import React, { useContext, useEffect } from 'react'
// import { makeStyles } from 'tss-react/mui'
// import presentationStyle from 'assets/jss/material-kit-pro-react/views/presentationStyle'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'
import Link from '@mui/material/Link'
// import Button from '@material-ui/core/Button'
import DialogContent from '@mui/material/DialogContent'
import { useParams } from 'react-router'

// import Tooltip from '@material-ui/core/Tooltip'
import DialogContentText from '@mui/material/DialogContentText'
import { useTranslation, Trans } from 'react-i18next'
import { AppContext } from '../../context/AppState'
import PropTypes from 'prop-types'
import OpentimestampsHeader from './OpentimestampsHeader'
import Hidden from '@mui/material/Hidden'
import DialogActions from '@mui/material/DialogActions'
import Button from 'components/CustomButtons/Button'
import './style.css'
import { getTraceabilityFacilitiesTrackAndTrace, getTraceabilityProductsTrackAndTrace } from 'actions/AppActions'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

// import { Controller, Scene } from 'react-scrollmagic'
import XdaiTable from './XdaiTable'

const strongType = (
  <strong key="strongType"></strong>
)
const cursive = (
  <em key="cursive"></em>
)

function VerifyWithTrusty (props) {
  const { state } = useContext(AppContext)
  const { prod, lot } = useParams()
  const { company, currLot, lotNumber } = state
  const { t } = useTranslation('verifyWithTrusty')
  const { onClose, open, isCalledBy, events } = props
  const [facilities, setFacilities] = React.useState([])
  const [products, setProducts] = React.useState([])
  const [glnName, setGlnName] = React.useState({})
  const [gtinName, setGtinName] = React.useState({})
  const name = company.name

  const companyLink = (
    <Link target='_blank' key="companyLink" href={company.website}>
      {{ name }}
    </Link>

  )
  const facilityParams = {
    limit: Number.MAX_SAFE_INTEGER,
    fields: 'name,gln,uuid'
  }

  const productParams = {
    limit: Number.MAX_SAFE_INTEGER,
    fields: 'name,gtin,uuid'
  }
  // NOTE: useEffect utile per recuperare le facilies che dovrò mostrare nel tabela
  useEffect(() => {
    if (isCalledBy.includes('events')) {
      // eslint-disable-next-line no-inner-declarations
      async function fetchFacilities () {
        const facilities = await getTraceabilityFacilitiesTrackAndTrace(prod, lot, facilityParams)
        setFacilities(facilities)
        const mapFac = {}
        facilities.forEach(x => {
          mapFac[x.gln] = x.name
        })
        setGlnName(mapFac)
      }
      // eslint-disable-next-line no-inner-declarations
      async function fetchProducts () {
        const products = await getTraceabilityProductsTrackAndTrace(prod, lot, productParams)
        setProducts(products)
        const mapProd = {}
        products.forEach(x => {
          if (company.organizationId === '5ad4a56c-9326-43e5-a236-f1a99f1edf66') { // HACK: affinché venga creata la giusta mappa per i prodotti fooditaliae
            if (x.gtin !== '3989914746255.010010' && x.gtin !== '3989914746255.050010' && x.gtin !== '3989914746255.010020') { // HACK: il gtin delle materie prime in fooditaliae alla quale non va aggiunto lo zero (sugli eventi di tracciabilità, il gtin della materia prima non è anticipato dallo 0)
              // NOTE: doppia aggiunta, per mantenere la compatibilità con la versione vecchia sviluppata da sas che prevedeva lo 0 davanti ai gtin
              mapProd[`0${x.gtin}`] = x.name
              mapProd[x.gtin] = x.name
            } else {
              mapProd[x.gtin] = x.name
            }
          } else {
            mapProd[x.gtin] = x.name
          }
        })
        setGtinName(mapProd)
      }
      fetchFacilities()
      fetchProducts()
    }
  }, [])
  const componentToRender = (isCalledBy) => {
    switch (isCalledBy) {
      case 'lots':
        return <OpentimestampsHeader company={company} lot={currLot} lotNumber={lotNumber} />
      case 'events':
        return <XdaiTable events={events} glnName={glnName} gtinName={gtinName}></XdaiTable>
    }
  }
  return (
    <>
      {/* Per il desktop */}
      <Hidden xsDown>
        <Dialog maxWidth='lg' onClose={onClose} open={open}>
          <DialogTitle className="fredoka">{t('title')}</DialogTitle>
          <DialogContent>

            <DialogContentText >
              <Trans i18nKey='verifyWithTrusty'>
                {[strongType,
                  strongType,
                  cursive,
                  companyLink
                ]}

              </Trans>
            </DialogContentText>
            <div>
              {componentToRender(isCalledBy)}
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>
              {t('close')}
            </Button>
          </DialogActions>
        </Dialog>
      </Hidden>
      {/* Per il mobile */}
      <Hidden smUp>
        <Dialog onClose={onClose} open={open} fullScreen={true}>
          <DialogTitle component="h2" className="fredoka">{t('title')}</DialogTitle>
          <DialogContent>
            <DialogContentText style={{ fontFamily: '"Raleway", sans-serif' }}>
              <Trans i18nKey='verifyWithTrusty'>
                {[strongType,
                  strongType,
                  cursive,
                  companyLink
                ]}
              </Trans>
            </DialogContentText>
            {/* //TODO: rimettere scroll magic */}
            {/* <Controller>
              <Scene duration={1000} classToggle="hideIcon" triggerElement="#trigger" indicators={false}>
                {() => (
                  <div className="vIndicatorContainer" style={{ marginTop: 30 }}>
                    <div className="vIconContainer">
                      <ExpandMoreIcon/>
                    </div>
                  </div>
                )}
              </Scene>
            </Controller> */}

            <div id='trigger' className="triggerOne">
            </div>
            <div className="openTimeStampContainer">
              {componentToRender(isCalledBy)}
            </div>

            <div className="vIndicatorContainerTable" style={{ marginTop: 30 }} >
              <div className="olIconContainer">
                <ChevronRightIcon/>
              </div>
              <div className="orIconContainer">
                <ArrowForwardIosIcon/>
              </div>
            </div>

          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="generalButton">
              {t('close')}
            </Button>
          </DialogActions>
        </Dialog>
      </Hidden>
    </>
  )
}
VerifyWithTrusty.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
}
export default VerifyWithTrusty
