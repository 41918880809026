import React, { useContext, useEffect } from 'react'
import { AppContext } from '../../context/AppState'
import AttributesGrid from 'components/AttributesGrid/AttributesGrid'
import DescriptionSection from 'components/AttributesGrid/DescriptionSection'
import MediaSection from 'components/AttributesGrid/MediaSection'
import MediaSectionDesktop from 'components/AttributesGrid/MediaSectionDesktop'
import { Box } from '@mui/material'
import { hideOrShowProperty } from 'utils/utils'
const ProductPage = (props) => {
  const { state } = useContext(AppContext)
  const { sectionActive, discover } = state
  const { mobile } = props
  useEffect(() => {
    window.scrollTo(0, 0)
    console.log('window', window)
  }, [sectionActive])

  return (
    <>
      {hideOrShowProperty(discover, 'attributes', 'product') && <AttributesGrid />}
      <Box component="span" display={sectionActive !== 1 ? 'none' : 'block'}>
        {hideOrShowProperty(discover, 'description', 'product') && <DescriptionSection />}
      </Box>
      {hideOrShowProperty(discover, 'additionalAttributes', 'product') && mobile
        ? <MediaSection />
        : <MediaSectionDesktop />
      }
    </>
  )
}
export default ProductPage
