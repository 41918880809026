import React, { useContext, useEffect } from 'react'
import { CardMedia, CardContent, useMediaQuery } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useTranslation } from 'react-i18next'

import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Card from 'components/Card/Card'
import Map from 'components/Map/Map'
import TextWithUrl from 'components/TextWithUrl/TextWithUrl'
import Skeleton from 'react-loading-skeleton'
import { AppContext } from '../../context/AppState'
import {
  Room as RoomIcon,
  Email as EmailIcon,
  Web as WebIcon,
  Store as StoreIcon,
  Call as CallIcon
} from '@mui/icons-material'

import profile from 'assets/img/img-placeholder.png'
import presentationStyle from 'assets/jss/material-kit-pro-react/views/presentationStyle'
import Link from '@mui/material/Link'
import './style.css'

const useStyles = makeStyles()(presentationStyle)

const CompanyInfo = () => {
  const { classes } = useStyles()
  const isSmall = useMediaQuery('(max-width: 600px)')
  const { t } = useTranslation('companyInfo')
  const { state } = useContext(AppContext)
  const { company, sectionActive } = state

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [sectionActive])

  const extractProductionKey = (attributeKey) => {
    let translatedString = ''
    if (attributeKey.includes('productions')) {
      const productionsArray = attributeKey.split(' ')
      const [prodString, ...rest] = productionsArray
      translatedString = [t(prodString), ...rest].join(' ')
    } else {
      translatedString = t(attributeKey)
    }

    return translatedString
  }

  return <GridContainer style={isSmall ? { padding: 0, marginTop: '16px' } : { padding: 0, background: 'white' }} className={classes.contentContainer} spacing={4}>
    <GridItem xs={12} style={{ paddingTop: '26px' }}>
      <Card style={isSmall ? { padding: '20px', marginTop: 10, marginBottom: 20 } : { padding: '20px 30px', marginTop: 10, marginBottom: 16, boxShadow: 'none' }}>
        <GridContainer direction="column" justify="center" alignItems="center">
          <GridItem className={classes.alignCenter} xs={12}>
            <CardMedia component="img" className={isSmall ? classes.cardMediaMobile : classes.cardMedia} image={(company && company.logo && company.logo.cropped ? company.logo.cropped : profile) || <Skeleton duration={0.7} width={300} height={300} /> } />
          </GridItem>
          <GridItem className={classes.alignCenter} xs={12}>
            <h1 style={{ padding: 15 }} className={classes.cardTitle}>{company.name}</h1>
          </GridItem>
        </GridContainer>
        <CardContent style={{ padding: 0 }}>
          <h5 className={classes.cardTitleSmall}>{t('description')}</h5>
          {company.name && company.description &&
          (<TextWithUrl className="companyDescriptionContainer" companyName={company.name} text={company.description}></TextWithUrl>
          )}
          <GridContainer style={{ marginTop: '40px' }} direction="row" justify="center" alignItems="flex-start">
            <GridItem xs={12} md={6}>
              <h4 className={classes.cardTitleSmall}>{t('titleInfo')}</h4>
              {company && company.address &&
              <p style={{ display: 'flex', alignItems: 'center' }}>
                <RoomIcon
                  style={{
                    color: '#3C4858',
                    marginRight: 10
                  }}
                />
                <span className={classes.boldText}>{`${t('address')}:`}</span>
                {`${company && company.address && company.address.street}, ${company && company.address && company.address.city}`}
                <br></br>
                {/* <span className={classes.boldText}>{`${t('city')}: `}</span>
                {`${company && company.address && company.address.city}`} */}
              </p>
              }
              {company && company.email
                ? <p style={{ display: 'flex', alignItems: 'center' }}>
                  <EmailIcon
                    style={{
                      color: '#3C4858',
                      marginRight: 10
                    }}
                  />
                  <span className={classes.boldText}>{`${t('email')}: `}</span>
                  <Link color="inherit" href={`mailto:${company.email}`}>{company.email}</Link>
                </p>
                : ''}
              {company && company.website
                ? <p style={{ display: 'flex', alignItems: 'center' }}>
                  <WebIcon
                    style={{
                      color: '#3C4858',
                      marginRight: 10
                    }}
                  />
                  <span className={classes.boldText}>{`${t('website')}: `}</span>
                  <Link color="inherit" href={`${company.website}`}>{company.website}</Link>
                </p>
                : ''}
              {company && company.ecommerce
                ? <p style={{ display: 'flex', alignItems: 'center' }}>
                  <StoreIcon
                    style={{
                      color: '#3C4858',
                      marginRight: 10
                    }}
                  />
                  <span className={classes.boldText}>{`${t('ecommerce')}: `}</span>
                  <Link color="inherit" href={`${company.ecommerce}`}>{company.ecommerce}</Link>
                </p>
                : ''}
              {company && company.phoneNumber
                ? <p style={{ display: 'flex', alignItems: 'center' }}>
                  <CallIcon
                    style={{
                      color: '#3C4858',
                      marginRight: 10
                    }}
                  />
                  <span className={classes.boldText}>{`${t('phoneNumber')}: `}</span>
                  <Link color="inherit" href={`tel:${company.phoneNumber}`}>{company.phoneNumber}</Link>
                </p>
                : ''}
              {company && company.mobileNumber
                ? <p style={{ display: 'flex', alignItems: 'center' }}>
                  <CallIcon
                    style={{
                      color: '#3C4858',
                      marginRight: 10
                    }}
                  />
                  <span className={classes.boldText}>{`${t('mobileNumber')}: `}</span>
                  <Link color="inherit" href={`tel:${company.mobileNumber}`}>{company.mobileNumber}</Link>
                </p>
                : ''}
              {company && company.attributes
                ? company.attributes.map((attribute, index) => <p key={`attribute-${index}`}>
                  <span className={classes.boldText}>{`${extractProductionKey(attribute.name)}: `}
                  </span>{ attribute.value } {attribute.name === 'surface' ? t('surfaceUnit')
                    : ''}
                </p>)
                : ''}
            </GridItem>

            {company && company.geolocation && company.geolocation.lat && company.geolocation.lng
              ? <GridItem xs={12} md={6}>
                <h4 className={classes.cardTitleSmall}>{t('map')}</h4>
                <div className={classes.companyImgContainer}>
                  <Map zoom={14} isHeader={true} lat={String(company.geolocation.lat)} lng={String(company.geolocation.lng)} />
                </div>
              </GridItem> : ''}

          </GridContainer>
        </CardContent>
      </Card>
    </GridItem>
  </GridContainer>
}

export default CompanyInfo
