import React, { useContext, useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import useMediaQuery from '@mui/material/useMediaQuery'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import { useTranslation } from 'react-i18next'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { AppContext } from '../../context/AppState'
import { alog } from 'utils/apioLog'
import Button from 'components/CustomButtons/Button.js'

import {
  Typography,
  CardContent,
  CardActions,
  Card
} from '@mui/material'
import presentationStyle from 'assets/jss/material-kit-pro-react/views/presentationStyle'
// import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined'
import YouTube from 'react-youtube'

// import Carousel, { Modal, ModalGateway } from 'react-images'
import './style.css'

const useStyles = makeStyles()(presentationStyle)
const openPage = (link) => {
  alog('link', link, 'DEBUG', 'info')

  window.open(link, '_blank')
}
const MediaSection = () => {
  const { classes } = useStyles()
  const { state } = useContext(AppContext)
  const { product } = state
  const [images, setImages] = useState([])
  const [videos, setVideos] = useState([])
  const [shouldComponentLoaded, setShouldComponentLoaded] = useState(false)
  const [cta, setCta] = useState([])
  //   const [player, setPlayer] = useState(null)

  const { t } = useTranslation('attributesGrid')

  //   const onReady = (event) => {
  //     console.log('event::', event)
  //     // eslint-disable-next-line
  //     setPlayer(event.target)
  //   }

  useEffect(() => {
    const images = product.additionalAttributes
      .filter(element => element.type === 'image')
      .map(elem => ({
        src: elem.link,
        key: elem.link + Math.random(),
        description: elem.description
      }))

    alog('images:::::::', images, 'DEBUG', 'info')

    setImages(images)

    const videos = product.additionalAttributes
      .filter(element => element.type === 'video')
    alog('videos:::::::', videos, 'DEBUG', 'info')

    setVideos(videos)

    const cta = product.additionalAttributes
      .filter(element => element.type === 'button')
    alog('cta:::::::', cta, 'DEBUG', 'info')

    setCta(cta)

    if (images.length || videos.length || cta.length) {
      setShouldComponentLoaded(true)
    } else {
      setShouldComponentLoaded(false)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isSmall = useMediaQuery('(max-width: 600px)')

  if (shouldComponentLoaded) {
    return <GridContainer className={classes.contentContainer} style={isSmall ? { paddingLeft: '0px', paddingRight: '0px', marginTop: '16px' } : {}}>
      <GridItem xs={12} md={12} xl={12}></GridItem>
      {images && images.length > 0
        ? <>
          <GridItem xs={12} md={12} xl={12}>
            <Typography variant="h4" component="p" className={classes.fredoka} style={{ color: '#3C4858', margin: '0px 0 20px 0' }}>
              {`${t('image')}`}
            </Typography>
          </GridItem>
          <GridItem xs={12} md={12} xl={12}>
            <Carousel
              dynamicHeight={true}
              showArrows={true}
              autoPlay={false}
              autoFocus={false}
              infiniteLoop={true}
              //   onChange={onChange}
              //   onClickItem={onClickItem}
              //   onClickThumb={onClickThumb}
              showStatus={false}
            >
              {images.map(img => {
                alog('Img', img, 'DEBUG', 'info')
                return <div style={{ background: 'white' }} key={Math.random()}>
                  <img className="productImage" alt={img.description} src={img.src} />
                  {img.description &&
                  <p className='legend'>{img.description}</p>
                  }
                </div>
              })}
            </Carousel>
          </GridItem>
        </>
        : null
      }
      {videos && videos.length > 0
        ? <>
          <GridItem xs={12} md={12} xl={12}></GridItem>
          <GridItem xs={12} md={12} xl={12}>
            <Typography variant="h4" component="p" className={classes.fredoka} style={{ color: '#3C4858', margin: '0px 0 20px 0' }}>
              {`${t('video')}`}
            </Typography>
          </GridItem>
          {videos.map(elem => {
            if (new URL(elem.link).hostname.includes('youtu.be')) {
              const videoId = elem.link.slice(elem.link.lastIndexOf('/') + 1)
              return (
                <GridItem key={`${elem.link}`} xs={12} md={12} xl={12}>
                  <div className={classes.videoWrapper}>
                    <YouTube
                      className={classes.videoWrapperFrame}
                      opts={{
                        height: '315',
                        width: '560',
                        playerVars: {
                          // https://developers.google.com/youtube/player_parameters
                          autoplay: 0

                        }
                      }}
                      videoId={videoId} />
                  </div>
                  <CardContent style={{ padding: '10px', background: '#fafafa' }}>
                    <Typography style={{ padding: ' 10px' }} variant="h6" color="textSecondary" component="p">
                      {elem.description}
                    </Typography>
                  </CardContent>
                </GridItem>
              )
            } else if (new URL(elem.link).hostname.includes('vimeo')) {
              const videoId = elem.link.slice(elem.link.lastIndexOf('/') + 1)
              return (
                <GridItem key={`${elem.link}`} xs={12} md={12} xl={12}>
                  <div className={classes.videoWrapper}>
                    <iframe
                      title = {`https://player.vimeo.com/video/${videoId}`}
                      className={classes.videoWrapperFrame}
                      width="560" height="315"
                      src={`https://player.vimeo.com/video/${videoId}?title=0&byline=0&portrait=0`}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen></iframe>
                  </div>
                  <CardContent style={{ padding: '10px', background: '#fafafa' }}>
                    <Typography style={{ padding: ' 10px' }} variant="h6" color="textSecondary" component="p">
                      {elem.description}
                    </Typography>
                  </CardContent>

                </GridItem>
              )
            } else {
              return (
                <GridItem key={`${elem.link}`} xs={12} md={12} xl={12} className={classes.contentGrid}>
                  <div className={classes.videoWrapper}>
                    <video controls className={classes.videoWrapperFrame} src={(elem.link)} />
                  </div>
                  <CardContent style={{ padding: '10px 0', background: '#fafafa' }}>
                    <Typography style={{ padding: '10px' }} variant="h6" color="textSecondary" component="p">
                      {elem.description}
                    </Typography>
                  </CardContent>
                </GridItem>
              )
            }
          })}
        </>
        : null
      }
      {cta && cta.length > 0
        ? <>
          {cta.map(elem =>
            (<GridItem key={`${elem.link}`} xs={12} md={6} xl={6}>
              <Card style={{ padding: 20 }}>
                <CardContent align="center">
                  <Typography variant="h5" color="textSecondary" component="h5" className="fredoka">
                    {elem.description}
                  </Typography>
                  <Typography variant="body2" component="p">
                    {/* {`${t('callToActionSentence')}`} */}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    onClick={() => openPage(elem.link)}
                    className={`${classes.companyButton} btnLink`}
                    variant="contained"
                    // endIcon={<ArrowForwardIosOutlinedIcon />}
                    color='ctaButton'
                    style={{ width: '100%' }}
                  >
                    {`${t('callToActionSentence')}`}
                  </Button>

                </CardActions>
              </Card>
            </GridItem>)
          )}
        </>
        : null
      }
    </GridContainer>
  } else {
    return null
  }
}
export default MediaSection
