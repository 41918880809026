import React from 'react'
// import Icon from 'react-crypto-icons
import { blockchainIcons } from 'blockchainIcons'
import { makeStyles } from 'tss-react/mui'
import presentationStyle from 'assets/jss/material-kit-pro-react/views/presentationStyle.js'

// TODO: aggiungere il tooltip con il nome della blockchain particolare
const useStyles = makeStyles()(presentationStyle)

function BlockchainIcons (props) {
  const { blockchain } = props
  const { classes } = useStyles()
  return (
    <>
     <div>
     <img className={classes.blockchainIcon} src={blockchainIcons[blockchain]} alt={`${blockchainIcons[blockchain]} logo`}/>
        </div>
    </>
  )
}

export default BlockchainIcons
