import React, { useContext } from 'react'
// nodejs library that concatenates classes
import classNames from 'classnames'
// @material-ui/core components
import { makeStyles } from 'tss-react/mui'
import { AppContext } from '../../context/AppState'
// import { useTranslation } from 'react-i18next'
import { Helmet } from "react-helmet";
// core components
// import RegularButton from 'components/CustomButtons/Button.js'
import CardHeader from 'components/Card/CardHeader.js'
import TextWithUrl from 'components/TextWithUrl/TextWithUrl'
import organizationToRules from 'organizationToRules'

import ProductPage from 'components/ProductPages/ProductPage'
// import Avatar from '@material-ui/core/Avatar'

import CardBody from 'components/Card/CardBody.js'
import Menu from 'components/Menu/Menu'
import presentationStyle from 'assets/jss/material-kit-pro-react/views/presentationStyle.js'
import Skeleton from 'react-loading-skeleton'
import { Box } from '@mui/material'
import SocialLinks from 'components/SocialLinks/SocialLinks.js'
import { hideOrShowProperty } from '../../utils/utils'

// import BuyNowModal from 'components/BuyNowModal/BuyNowModal.js'

// import { changeLanguage } from 'utils/translations'
// import CompanyVerificationBanner from 'components/CompanyVerificationBanner'

const useStyles = makeStyles()(presentationStyle)

// main hook
export default function PresentationPageMobile (props) {
  // definisco stili e media query
  const { classes } = useStyles()
  const { state } = useContext(AppContext)
  const { product, company, sectionActive, currLot, discover } = state
  // const { t } = useTranslation('presentationPage')
  // const handleClose = () => {
  //   setOpenBuyNow(false)
  // }
  // useEffect(() => {
  //   window.scrollTo(0, 0)
  // }, [sectionActive])
  return (
    <>
      <Helmet>
      <title> {`${product.name} | ${company.name} | Lot ${currLot.lotNumber}`}</title>
        <meta name="description" content={product.description} />
        <meta property="og:image"
          content={
            product && product.profileImage && product.profileImage.cropped
              ? product.profileImage.cropped
              : null
          }
        />
      </Helmet>

      {/* {openBuyNow
          ? <BuyNowModal
            openBuyNow={openBuyNow}
            onClose={handleClose}
          ></BuyNowModal>
          : null
        } */}
      <CardHeader style={{ display: 'flex', justifyContent: 'center' }}>
        {
          (sectionActive === 1 || sectionActive === 3 || sectionActive === 4 || sectionActive === 5 || sectionActive === 6) &&
          hideOrShowProperty(discover, 'profileImage', 'product') && product && product.profileImage && product.profileImage.cropped
            ? <Box component="div" display={sectionActive !== 1 && sectionActive !== 5 && sectionActive !== 6 && sectionActive !== 3 && sectionActive !== 4 ? 'none' : 'block'} className={classNames(classes.imgContainer, classes.roundedCircle)} style={{ minWidth: '100px', width: '200px', height: '200px', backgroundImage: `url('${product.profileImage.cropped}')` }}></Box>
            : <Box display={(sectionActive !== 1 && sectionActive !== 5 && sectionActive !== 6 && sectionActive !== 3 && sectionActive !== 4) ? 'none' : 'block'} className={classNames(classes.imgContainer, classes.roundedCircle)} style={{ minWidth: '100px', width: '200px', height: '200px' }}>
              <Skeleton duration={0.7} width={200} height={200} circle={true} />
            </Box>
        }
        {
          sectionActive === 2 &&
            company && company.logo && company.logo.cropped
            ? <Box component="div" display={(sectionActive !== 2) ? 'none' : 'block'} className={classNames(classes.logoContainer, classes.roundedCircle)} style={{ minWidth: '100px', width: '200px', height: '200px', backgroundImage: `url('${company.logo.cropped}')`, backgroundColor: '#ffffff' }}></Box>
            : <Box display={(sectionActive !== 2) ? 'none' : 'block'} className={classNames(classes.logoContainer, classes.roundedCircle)} style={{ minWidth: '100px', width: '200px', height: '200px' }}>
              <Skeleton duration={0.7} width={200} height={200} circle={true} />
            </Box>
        }
      </CardHeader>
      {organizationToRules[company.organizationId] && organizationToRules[company.organizationId].footer &&
      <p>Copyright © 2022 Conad Centro Nord Società Cooperativa - Tutti i diritti riservati - P.Iva: 01751820356</p>
      }
      <CardBody style={{ padding: 0, paddingBottom: 50 }}>
        <div className={classes.cardBodyContainer}>
          {/* <CompanyVerificationBanner /> */}
          {/* nome */}
          {(sectionActive === 1 || sectionActive === 5 || sectionActive === 3 || sectionActive === 4 || sectionActive === 6) &&
          <>
            <h1 className={classes.cardTitle} style={{ paddingTop: (sectionActive !== 1 && sectionActive !== 5 && sectionActive !== 6 && sectionActive !== 3 && sectionActive !== 4) ? 64 : 0 }}>{hideOrShowProperty(discover, 'name', 'product') && product && product.name}</h1>
          </>
          }
          {sectionActive === 2 &&
            <h1 className={classes.cardTitle} style={{ paddingTop: (sectionActive !== 2) ? 64 : 70 }}>{company && company.name}</h1>
          }

          {/* social link */}
          <SocialLinks />
          {/* descrizione */}
          {sectionActive === 1 &&
            <>
              <ProductPage
                mobile={true} />
            </>

          }
          {(sectionActive === 2) &&
            <Box component="span" display={'block'} style={{ margin: '20px 0' }}>
              {company.name && company.description &&
                <>
                  <TextWithUrl companyName={company.name} text={company.description}></TextWithUrl>
                </>
              }
            </Box>
          }
          <Menu />
        </div>
      </CardBody>
    </>
  )
}
