import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'
import useMediaQuery from '@mui/material/useMediaQuery'
import presentationStyle from 'assets/jss/material-kit-pro-react/views/presentationStyle'
import { AppContext } from '../../context/AppState'

import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'

import { Table, TableContainer, TableHead, TableRow, TableCell, Paper, TableBody, Typography, Box } from '@mui/material'
import Card from 'components/Card/Card'

const useStyles = makeStyles()(presentationStyle)

const Disposal = () => {
  const { classes } = useStyles()
  const isSmall = useMediaQuery('max-height: 600px')
  const { t } = useTranslation('wasteTable')

  const { state } = useContext(AppContext)
  const { product, sectionActive } = state

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [sectionActive])
  const waste = product.waste
  // Rimuovo _id altrimenti viene renderizzato come una riga della tabella
  //   if (waste) {
  //     delete waste._id
  //     delete waste.createdAt
  //     delete waste.updatedAt
  //   }

  return <div className={classes.lotContainer} style={isSmall ? { marginTop: '16px' } : { background: 'white' }}>
    <GridContainer style={{ width: '100%' }} direction="row" justify="center" alignItems="center" spacing={6}>
      <GridItem xs={12} style={isSmall ? { paddingLeft: '2px', paddingRight: '2px' } : {}}>
        {waste ? <TableContainer component={Paper}>
          <Table className="wasteTable">
            <TableHead>
              <TableRow>
                <TableCell>{t('component')}</TableCell>
                <TableCell>{t('type')}</TableCell>
                <TableCell>{t('material')}</TableCell>
                <TableCell>{t('wasteCollection')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {waste.map((element, index) => <TableRow key={`allergene-${index}`}>
                <TableCell style={{ fontWeight: 'bold' }}>{t(`componentType.${element.component}`)}</TableCell>
                <TableCell>{element.componentType === 'monocomponent' ? t('materials.monoComponent') : t('materials.multiComponent')}</TableCell>
                {element.material && element.material.id !== 19 &&
                element.material.id !== 5 &&
                element.material.id !== 4 &&
                element.material.id !== 1 &&
                <TableCell >{`${t(`materials.${element.material.name}`)}/${t(`materials.${element.material.materials[0].name}`)}`}</TableCell>
                }
                {element.material && (element.material.id === 19 ||
                element.material.id === 5 ||
                element.material.id === 4 ||
                element.material.id === 1) &&
                <TableCell >{`${t(`materials.${element.material.name}`)}`}</TableCell>
                }
                <TableCell >{`${t(`wasteCollectionType.${element.wasteCollection}`)}`}</TableCell>
              </TableRow>)}
            </TableBody>
          </Table>
        </TableContainer> : (
          <Card className={classes.cardContainer}>
            <Box pt={4} pb={4}>
              <Typography variant='body2' component='div' className={classes.cardDescription}>
                Non ci sono allergeni da mostrare
              </Typography>
            </Box>
          </Card>)}
      </GridItem>
    </GridContainer>
  </div>
}

export default Disposal
