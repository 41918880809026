import React, { useContext, useEffect, useState, useCallback } from 'react'
import { makeStyles } from 'tss-react/mui'
import useMediaQuery from '@mui/material/useMediaQuery'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import { useTranslation } from 'react-i18next'
import { AppContext } from '../../context/AppState'
import { alog } from 'utils/apioLog'

import {
  Typography,
  CardContent,
  CardActions,
  Card,
  Button
} from '@mui/material'
import presentationStyle from 'assets/jss/material-kit-pro-react/views/presentationStyle'
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined'
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from 'react-image-gallery';
// import Carousel, { Modal, ModalGateway } from 'react-images'
import './style.css'
import YouTube from 'react-youtube'

const useStyles = makeStyles()(presentationStyle)
const openPage = (link) => {
  alog('link', link, 'DEBUG', 'info')
  window.open(link, '_blank')
}
const MediaSectionDesktop = () => {
  const { classes } = useStyles()
  const { state } = useContext(AppContext)
  const { product } = state
  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)
  const [images, setImages] = useState([])
  const [videos, setVideos] = useState([])
  const [cta, setCta] = useState([])
  const { t } = useTranslation('attributesGrid')

  useEffect(() => {
    const images = product.additionalAttributes
      .filter(element => element.type === 'image')
      .map(elem => ({
        original: elem.link,
          thumbnail: elem.link
      }))
    alog('images:::::::', images, 'DEBUG', 'info')

    setImages(images)

    const videos = product.additionalAttributes
      .filter(element => element.type === 'video')
    alog('videos:::::::', videos, 'DEBUG', 'info')

    setVideos(videos)

    const cta = product.additionalAttributes
      .filter(element => element.type === 'button')
    alog('cta:::::::', cta, 'DEBUG', 'info')

    setCta(cta)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const isSmall = useMediaQuery('(max-width: 600px)')

  return <GridContainer className={classes.contentContainer} style={isSmall ? { paddingLeft: '0px', paddingRight: '0px', marginTop: '16px' } : { background: 'white' }}>
    {images && images.length > 0
      ? <>
        <GridItem xs={12} md={12} xl={12}></GridItem>
        <GridItem xs={12} md={12} xl={12}>
          <Typography variant="h4" component="p" style={{ margin: '30px 0 20px 0' }}>
            {`${t('image')}`}
          </Typography>
          
        </GridItem>
        <GridItem xs={12} md={12} xl={12}>
          <div style={{marginTop:20}}>
            <ImageGallery items={images} />
          </div>
        </GridItem>
      </>
      : null
    }
    {videos && videos.length > 0
      ? <>
        <GridItem xs={12} md={12} xl={12}></GridItem>
        <GridItem xs={12} md={12} xl={12}>
          <Typography variant="h4" component="p" style={{ margin: '30px 0 20px 0' }}>
            {`${t('video')}`}
          </Typography>
        </GridItem>
        {videos.map(elem => {
          if (new URL(elem.link).hostname.includes('youtu.be')) {
            const videoId = elem.link.slice(elem.link.lastIndexOf('/') + 1)
            return (
              <GridItem key={`${elem.link}`} xs={12} md={12} xl={12}>
                <div className={classes.videoWrapper}>
                  <YouTube
                    className={classes.videoWrapperFrame}
                    opts={{
                      height: '315',
                      width: '560',
                      playerVars: {
                        // https://developers.google.com/youtube/player_parameters
                        autoplay: 0

                      }
                    }}
                    videoId={videoId} />;
                </div>

                <CardContent style={{ padding: '10px', background: '#fafafa' }}>
                  <Typography style={{ padding: ' 10px' }} variant="h6" color="textSecondary" component="p">
                    {elem.description}
                  </Typography>
                </CardContent>

              </GridItem>
            )
          } else if (new URL(elem.link).hostname.includes('vimeo')) {
            const videoId = elem.link.slice(elem.link.lastIndexOf('/') + 1)
            return (
              <GridItem key={`${elem.link}`} xs={12} md={12} xl={12}>
                <div className={classes.videoWrapper}>
                  <iframe
                    title = {`https://player.vimeo.com/video/${videoId}`}
                    className={classes.videoWrapperFrame}
                    width="560" height="315"
                    src={`https://player.vimeo.com/video/${videoId}?title=0&byline=0&portrait=0`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen></iframe>
                </div>

                <CardContent style={{ padding: '10px', background: '#fafafa' }}>
                  <Typography style={{ padding: ' 10px' }} variant="h6" color="textSecondary" component="p">
                    {elem.description}
                  </Typography>
                </CardContent>

              </GridItem>
            )
          } else {
            return (
              <GridItem key={`${elem.link}`} xs={12} md={12} xl={12} className={classes.contentGrid}>
                <div className={classes.videoWrapper}>
                  <video controls className={classes.videoWrapperFrame} src={(elem.link)} />
                </div>
                <CardContent style={{ padding: '10px 0', background: '#fafafa' }}>
                  <Typography style={{ padding: '10px' }} variant="h6" color="textSecondary" component="p">
                    {elem.description}
                  </Typography>
                </CardContent>
              </GridItem>
            )
          }
        })}
      </>
      : null
    }
    {cta && cta.length > 0
      ? <>
        {/* call to action dicitura */}

        {/* <GridItem xs={12} md={12} xl={12}></GridItem>
        <GridItem xs={12} md={12} xl={12}>
          <Typography variant="h4" component="p" style={{ margin: '30px 0 20px 0' }}>
            {`${t('callToAction')}`}
          </Typography>
        </GridItem> */}
        {cta.map(elem =>
          (<GridItem key={`${elem.link}`} xs={12} md={6} xl={6}>
            <Card>
              <CardContent>
                <Typography variant="h5" color="textSecondary" component="h5">
                  {elem.description}
                </Typography>
                <Typography variant="body2" component="p">
                  {/* {`${t('callToActionSentence')}`} */}
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  onClick={() => openPage(elem.link)}
                  className={`${classes.success} btnLink`}
                  variant="contained"
                  endIcon={<ArrowForwardIosOutlinedIcon />}
                >
                  {`${t('callToActionSentence')}`}
                </Button>

              </CardActions>
            </Card>
          </GridItem>)
        )}
      </>
      : null
    }
  </GridContainer>
}
export default MediaSectionDesktop
