import React from 'react'
import './style.css'
import logoImg from '../../assets/img/trusty-logo-black.svg'
import PropTypes from 'prop-types'
import { alog } from '../../utils/apioLog'
const SIZES = [
  'small',
  'medium',
  'large'
]

const COLORS = [
  'dark',
  'light'
]

function LogoLoader (props) {
  alog('props', props, 'DEBUG', 'info')
  const { size, text, color } = props
  const loaderSize = SIZES.includes(size) ? size : SIZES[0]
  const loaderColor = COLORS.includes(color) ? color : COLORS[0]
  return (
    <div className='logoLoaderContainer'>
      <img className={`logoLoader ${loaderSize} ${loaderColor}`} src={logoImg} alt='' />
      {text != null ? <h3>{text}</h3> : null}
    </div>
  )
}

LogoLoader.propTypes = {
  size: PropTypes.string,
  text: PropTypes.string,
  color: PropTypes.string
}
export default LogoLoader
