import React, { createContext, useReducer } from 'react'
import AppReducer from './AppReducer'

// Stato iniziale
const initialState = {
  product: {},
  company: {},
  facility: {},
  expirationDate: '',
  anchorMenu: null,
  currLot: {},
  lightLot: {},
  urlHasLot: false,
  openCert: false,
  rawMaterial: null,
  shippingTrack: null,
  selectedCert: {},
  openTrackDoc: false,
  selectedTrackDoc: {},
  openTrackDesc: false,
  selectedTrackDesc: {},
  lotNotFound: false,
  lightLotNotFound: false,
  lotLoaded: false,
  lightLotLoaded: false, // mi serve nel componente traceabilityLightLot per lo use effect del posizionamento nella pagina
  discover: {},
  lots: [],
  lotsSup: [],
  lotNumber: '',
  informationFound: true,
  informationLoaded: false
}

// Creazione del context per l'app
export const AppContext = createContext(initialState)

// Componente Provider con cui wrappare tutta l'app
export const AppProvider = ({ children }) => {
  // stato tradotto
  const [state, dispatch] = useReducer(AppReducer, initialState)

  // return the actual component
  return <AppContext.Provider value={{
    state,
    dispatch
  }}>
    {children}
  </AppContext.Provider>
}

/*
  /*   Spiegazione utilizzo stato   /*
  informationFound: viene settato inizialmente a true. Se nel caricamento iniziale (appena si approccia alla pagina) del prodotto non viene restituito nulla, esso viene settato a false. Altrimenti rimane a true.
  Responsabile del 404 Page not found

*/
