import React from 'react'
// import 'react-daterange-picker/dist/css/react-calendar.css'
// import 'react-date-range/dist/styles.css' // main css file
// import 'react-date-range/dist/theme/default.css' // theme css file
// import { Calendar } from 'react-date-range'
import 'moment/locale/it'
import { Fab } from '@mui/material'
import {
  Done as DoneIcon
} from '@mui/icons-material'

const DateCalendar = (props) => {
  const [date, setDate] = React.useState(null)

  const onSelect = (value, states) => {
    setDate(value)
    props.onSelectDate(value)
  }

  const handleClick = () => {
    props.handleClick(true)
  }

  return (
    <>

      <div className="">
        {/* TODO: rimettere il calendar in maniera corretta */}
        {/* <Calendar
          editableDateInputs={true}
          onChange={onSelect}
          date={date}
          className='customDataRange'
        /> */}
        <div className="doneContainer">
          <Fab style={{ background: 'transparent', boxShadow: 'none' }} onClick={handleClick}>
            <DoneIcon style={{ color: 'green' }}></DoneIcon>
          </Fab>
        </div>
      </div>
    </>
  )
}

export default DateCalendar
