import React, { useContext, useEffect } from 'react'
import moment from 'moment'
import { makeStyles } from 'tss-react/mui'
import { Typography, Link, Grid, Button } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import presentationStyle from 'assets/jss/material-kit-pro-react/views/presentationStyle'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import { useTranslation } from 'react-i18next'
import TraceabilityInput from './TraceabilityInput'
import RenderRightTraceability from './RenderRightTraceability'
// import OpentimestampsHeader from './OpentimestampsHeader'
import { AppContext } from '../../context/AppState'
// import LotVerificationBanner from 'components/LotVerificationBanner/LotVerificationBanner'
import { useNavigate, useLocation } from 'react-router'
import './style.css'
import TraceabilityExpDateTable from './TraceabilityExpDateTable'
import TraceabilityAllLots from './TraceabilityAllLots'
import { hideOrShowProperty } from 'utils/utils'
import organizationToRules from 'organizationToRules'
const useStyles = makeStyles()(presentationStyle)

const Traceability = () => {
  const { classes } = useStyles()
  const isSmall = useMediaQuery('(max-width: 960px)')
  const { t } = useTranslation('traceability')
  let navigate = useNavigate();
  let location = useLocation();
  // stato globale dell'app estrapolato dal context
  const { state, dispatch } = useContext(AppContext)
  const {
    urlHasLot,
    currLot,
    product,
    lotNumber,
    company,
    lotLoaded,
    lightLotLoaded,
    sectionActive,
    discover,
    lightLot
  } = state
  const { name, website } = company

  const companyLink = (
    <Link target='_blank' className={classes.companyWebsite} key="companyLink" href={website}>
      {{ name }}
    </Link>
  )

  useEffect(() => {
    window.scrollTo(0, 0)
    console.log('sono nello useEffect dello scroll to')
  }, [lotLoaded, sectionActive, lightLotLoaded])

  useEffect(() => {
    return () => {
      console.log('cleaned up')
    }
  }, [])

  const setDefaultPage = () => {
    dispatch({
      type: 'SET_LOT',
      payload: []
    })
    dispatch({
      type: 'SET_LOT_NUMBER',
      payload: ''
    })
    dispatch({
      type: 'SET_URL_HAS_LOT',
      payload: false
    })
    dispatch({
      type: 'SET_LOT_LOADED',
      payload: false
    })
    navigate(-1, { state: {fromProductPage: false}  });
  }
  
  const returnExpirationDate = () => {
    if (currLot.expirationDate) {
      return <Typography className="raleway" variant="body1" component="p">{`${t('expirationDate')}: `}<b>{moment(currLot.expirationDate).format('DD/MM/YYYY') || ''}</b></Typography>
    } else if (lightLot.expirationDate) {
      return <Typography className="raleway" variant="body1" component="p">{`${t('expirationDate')}: `}<b>{moment(lightLot.expirationDate).format('DD/MM/YYYY') || ''}</b></Typography>
    } else {
      return ''
    }
  }

  // NOTE: se urlHasLot allora devo poter caricare un componente che vede se il lotto è di mktg o di traceability
  return <div className={classes.lotContainer} style={isSmall ? { marginTop: '16px', paddingBottom: 0 } : { background: 'white', paddingBottom: urlHasLot ? 0 : '' }}>
    {urlHasLot || (product.metadata && Object.keys(product.metadata).length && product.metadata.showTraceability)
      ? <GridContainer justify="center" style={{ width: '100%' }} spacing={2}>
        <GridItem xs={12} style={{ textAlign: 'center' }}>
          {location && location.state && Object.keys(location.state).length > 0 &&
            location.state.fromProductPage &&
            <Button
              onClick={() => setDefaultPage()}
            >{t('goBack')}</Button>
          }
          {product.metadata && Object.keys(product.metadata).length && product.metadata.showTraceability && !urlHasLot
            ? <></>
            : <Typography className="fredoka" style={{ marginBottom: '12px' }} variant="h4" component="h4">{`${t('title')} ${lotNumber || 1}`}</Typography>
          }
          {hideOrShowProperty(discover, 'expirationDate', 'lot') &&
               returnExpirationDate()
          }
          {/* {lotLoaded && (<LotVerificationBanner></LotVerificationBanner>)} */}
        </GridItem>
        <RenderRightTraceability
          company={company}
          isSmall={isSmall}
          currLot={currLot}
          companyLink={companyLink}
          product={product}
          lightLot={lightLot}
        ></RenderRightTraceability>
      </GridContainer>
      : <Grid container display='flex' justify='center'>
        <Grid item xs={12} md={12}>
          <TraceabilityInput />
        </Grid>
        {(!organizationToRules[product.organizationId] || organizationToRules[product.organizationId].tableSearchLot) && // HACK: in order to not render table search all lots and by exp date
          <>
            <Grid item xs={12} md={12}></Grid>
            <Grid item xs={12} md={12}>
              <TraceabilityExpDateTable />
            </Grid>

            <Grid item xs={12} md={12}></Grid>
            <Grid item xs={12} md={12}>
              <TraceabilityAllLots />
            </Grid>
          </>
        }
      </Grid>
    }
  </div>
}

export default Traceability
