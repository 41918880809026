import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import { Typography, Link, Grid } from '@mui/material'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import { useTranslation, Trans } from 'react-i18next'
import TraceabilityMobileCard from './TraceabilityMobileCard'
import TraceabilityCard from './TraceabilityCard'
import RegularButton from '../CustomButtons/Button.js'
import VerifyWithTrusty from './VerifyWithTrusty'
import './style.css'
import { getTraceabilityEvents } from 'actions/AppActions'
// import LogoLoader from 'components/LogoLoaderImage'
import ProductTraceabilityCard from './ProductTraceabilityCard'
import ProductTraceabilityMobileCard from './ProductTraceabilityMobileCard'
import LightLotTraceabilityCard from './LightLotTraceabilityCard'
import LightLotTraceabilityMobileCard from './LightLotTraceabilityMobileCard'
import AttributesLotGrid from 'components/AttributesGrid/AttributesLotGrid'

const trustyLink = (
  <Link target='_blank' color="primary" key="trustyLink" href="https://www.trusty.id">
  </Link>
)

const strongType = (
  <strong key="strongType"></strong>
)
const cursive = (
  <em key="cursive"></em>
)

const RenderRightTraceability = (props) => {
  const { company, isSmall, currLot, companyLink, product, lightLot } = props
  const { prod, lot } = useParams()
  const [open, setOpen] = React.useState(false)
  const [isCalledBy, setIsCalledBy] = React.useState('lots')
  const [isClassicLotFromMarketing, setIsClassicLotFromMarketing] = React.useState(false)
  const [isLightLotFromMarketing, setIsLightLotFromMarketing] = React.useState(false)
  const [itHasEvents, setItHasEvents] = React.useState(false)
  const [events, setEvents] = React.useState([])
  const { t } = useTranslation('traceability')
  const shouldSetLotFromMarketing = currLot && Object.keys(currLot).length > 0 // devo caricare le informazioni del lotto che mi proviene dal marketing
  const shouldSetLightLotFromMarketing = lightLot && Object.keys(lightLot).length > 0 // devo caricare le informazioni del lotto che mi proviene dal marketing
  const shouldProductReady = product && Object.keys(product).length > 0
  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  // NOTE: gestire la casistica in cui vengano trovati sia light lot che lot normali (in teoria, per come è costruita la visualizzazione viene mostrato il lotto)

  useEffect(() => {
    console.log('sono cbhiamato')
    if (shouldSetLotFromMarketing) {
      setIsClassicLotFromMarketing(true)
    } else {
      // eslint-disable-next-line no-inner-declarations
      async function fetchEvents () {
        const events = await getTraceabilityEvents(prod, lot)
        setEvents(events)
      }
      if (shouldSetLightLotFromMarketing) {
        setIsLightLotFromMarketing(true)
      }
      fetchEvents()
    }
  }, [shouldSetLotFromMarketing, shouldSetLightLotFromMarketing])

  useEffect(() => {
    console.log('Light lots', lightLot)
    if (events.length > 0) {
      setIsCalledBy('events')
      setItHasEvents(true)
    }
  }, [events])

  // NOTE: se urlHasLot allora devo poter caricare un componente che vede se il lotto è di mktg o di traceability
  return isClassicLotFromMarketing
    ? <React.Fragment>
      {open &&
         <VerifyWithTrusty
           company={company}
           open={open}
           onClose={handleClose}
           isCalledBy={isCalledBy}
         ></VerifyWithTrusty>
      }

      {/* <OpentimestampsHeader company={company} lot={currLot} lotNumber={lotNumber} /> */}
      {isSmall
        ? currLot.traceability.map((track, index) => {
          return (track.showPhase
            ? (
              <TraceabilityMobileCard
                index={index}
                track={track}
                key={`${track.name}-${index}`}
                organizationId={currLot.organizationId}
              />)
            : null
          )
        })
        : <Grid container spacing={3} className="vittorio">
          {/* <Box component="div" className={classes.masonryWrapper} style={{ maxHeight: calcCardHeight(currLot.traceability) }}> */}
          {
            currLot.traceability.map((track, index) => {
              return (track.showPhase
                ? (<TraceabilityCard
                  organizationId={currLot.organizationId}
                  index={index}
                  track={track}
                  key={`${track.name}-${index}`} />)
                : null
              )
            }

            )
          }

        </Grid>
      }
      <GridContainer style={{ padding: '30px' }}>
        <GridItem xs={12} md={9}>
          <Typography className="descriptionSentences raleway">
            <Trans i18nKey='description'>
              {[trustyLink,
                strongType,
                cursive,
                companyLink
              ]}

            </Trans>
          </Typography>
        </GridItem>
        <GridItem xs={12} md={3}>
          <RegularButton
            color="ctaButton"
            onClick={handleClickOpen}
            round
            style={{ width: '100%' }}
            className="verifyButton"
          >
            {t('verifyWithTrusty')}
          </RegularButton>
        </GridItem>
      </GridContainer>
    </React.Fragment>
    : <>
      {open &&
         <VerifyWithTrusty
           company={company}
           open={open}
           onClose={handleClose}
           isCalledBy={isCalledBy}
           events={events}
         ></VerifyWithTrusty>
      }
      {itHasEvents && <GridContainer style={{ padding: '30px' }}>
        <GridItem xs={12} md={9}>
          <Typography className="descriptionSentences raleway">
            <Trans i18nKey='descriptionTraceability'>
              {[trustyLink,
                strongType,
                cursive,
                companyLink,
                strongType
              ]}

            </Trans>
          </Typography>
        </GridItem>
        <GridItem xs={12} md={3}>
          <RegularButton
            color="ctaButton"
            onClick={handleClickOpen}
            round
            style={{ width: '100%' }}
            className="verifyButton"
          >
            {t('discover')}
          </RegularButton>
        </GridItem>
      </GridContainer>
      }
      {isLightLotFromMarketing
        ? isSmall
          ? <>
            {shouldSetLightLotFromMarketing &&
          <AttributesLotGrid
            lightLot={lightLot}
          />}
            {shouldSetLightLotFromMarketing && lightLot.traceability.map((track, index) => {
              return <>
                <LightLotTraceabilityMobileCard
                  organizationId={lightLot.organizationId} // serve per filtrare sulle facilities
                  index={index}
                  track={track}
                  key={`${track.name}-${index}`} />
              </>
            })}
          </>
          : <Grid container spacing={3} className="vittorio">
            {/* <Box component="div" className={classes.masonryWrapper} style={{ maxHeight: calcCardHeight(currLot.traceability) }}> */}
            {
              shouldSetLightLotFromMarketing &&
              <AttributesLotGrid
                lightLot={lightLot}
              />
            }
            {shouldSetLightLotFromMarketing && lightLot.traceability.map((track, index) => {
              return <>
                <LightLotTraceabilityCard
                  organizationId={lightLot.organizationId} // serve per filtrare sulle facilities
                  index={index}
                  track={track}
                  key={`${track.name}-${index}`} />
              </>
            })}
          </Grid>
        : isSmall // TODO: fare la ProductTraceabilityMobileCard
          ? shouldProductReady && product.traceability.map((track, index) => {
            return <ProductTraceabilityMobileCard
              organizationId={product.organizationId} // serve per filtrare sulle facilities
              index={index}
              track={track}
              key={`${track.name}-${index}`} />
          })
          : <Grid container spacing={3} className="vittorio">
            {/* <Box component="div" className={classes.masonryWrapper} style={{ maxHeight: calcCardHeight(currLot.traceability) }}> */}
            {
              shouldProductReady && product.traceability.map((track, index) => {
                return <>
                  <ProductTraceabilityCard
                    organizationId={product.organizationId} // serve per filtrare sulle facilities
                    index={index}
                    track={track}
                    key={`${track.name}-${index}`} />
                </>
              }

              )
            }

          </Grid>
      }
    </>
  // : <LogoLoader></LogoLoader>
}

export default RenderRightTraceability
