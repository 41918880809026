import React, { useContext } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { makeStyles } from 'tss-react/mui'
import {FilePresent as FilePresentIcon} from '@mui/icons-material';

import { useTranslation } from 'react-i18next'
import {
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  IconButton,
  Paper} from '@mui/material';
import presentationStyle from 'assets/jss/material-kit-pro-react/views/presentationStyle'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import { AppContext } from '../../context/AppState'
import './style.css'

import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const useStyles = makeStyles()(presentationStyle)


  // Funzione che scarica il documento
const downloadDocument = (name, url) => {
  const a = document.createElement('a')
  document.body.appendChild(a)
  a.style = 'display: none'
  a.href = url
  a.target = '_blank'
  a.download = name
  a.click()
  window.URL.revokeObjectURL(url)
}




const CertificationsTable = () => {
  const { classes } = useStyles()
  const isSmall = useMediaQuery('(max-width: 600px)')
  const { t } = useTranslation('certificationsTable')
  const { state, dispatch } = useContext(AppContext)

  const { company } = state

  // funzione che apre modal documenti
  const handleOpenCert = (cert) => {
    dispatch({
      type: 'SET_CERT',
      payload: cert
    })
    dispatch({
      type: 'SET_OPEN_CERT',
      payload: true
    })
  }

  return <div className={classes.lotContainer} style={isSmall ? { marginTop: '16px' } : {}}>
    <GridContainer style={{ width: '100%' }}>
      <GridItem xs={12} style={isSmall ? { paddingLeft: '2px', paddingRight: '2px' } : {}}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className="fredoka">{t('title')}</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ display: 'block' }}>
            <div className="certificationsTable">
            <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>{t('name')}</TableCell>
                  <TableCell>{t('description')}</TableCell>
                  <TableCell>{t('authority')}</TableCell>
                  <TableCell>{t('protocolNum')}</TableCell>
                  <TableCell>{t('download')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {company.certifications.map((row) => (
                  <TableRow
                  key={row.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell>{row.description}</TableCell>
                    <TableCell>{row.authority}</TableCell>
                    <TableCell>{row.num}</TableCell>
                    <TableCell>{row.documents.map( (doc) => (
                      <IconButton 
                      aria-label="delete"
                      onClick={() => downloadDocument(doc.name, doc.fileUrl)} >
                        <FilePresentIcon />
                      </IconButton>
                    ))}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer> 
            </div>
          </AccordionDetails>
        </Accordion>
      </GridItem>
    </GridContainer>
  </div>
}

export default CertificationsTable
