import React, { useContext } from 'react'
// nodejs library that concatenates classes
import classNames from 'classnames'
// @material-ui/core components
import { makeStyles } from 'tss-react/mui'
// import { useTranslation } from 'react-i18next'
import './style.css'
// core components
// import RegularButton from 'components/CustomButtons/Button.js'
import CardHeader from 'components/Card/CardHeader.js'
import { Helmet } from "react-helmet";
import CardBody from 'components/Card/CardBody.js'
import Menu from 'components/Menu/Menu'
import presentationStyle from 'assets/jss/material-kit-pro-react/views/presentationStyle.js'
import Skeleton from 'react-loading-skeleton'
import { Box } from '@mui/material'
import SocialLinks from 'components/SocialLinks/SocialLinks.js'
import { AppContext } from '../../context/AppState'
import { hideOrShowProperty } from '../../utils/utils'

// import BuyNowModal from 'components/BuyNowModal/BuyNowModal.js'

// import { changeLanguage } from 'utils/translations'
// import CompanyVerificationBanner from 'components/CompanyVerificationBanner'

const useStyles = makeStyles()(presentationStyle)

// main hook
export default function PresentationPageDesktop (props) {
  // definisco stili e media query
  const { classes } = useStyles()
  const { state } = useContext(AppContext)
  const { product, company, currLot, discover } = state
  // const handleClose = () => {
  //   setOpenBuyNow(false)
  // }
  return (
    <>
      <Helmet>
      <title> {`${product.name} | ${company.name} | Lot ${currLot.lotNumber}`}</title>
        <meta name="description" content={product.description} />
        <meta property="og:image"
          content= {
            product && product.profileImage && product.profileImage.cropped
              ? product.profileImage.cropped
              : null
          }
        />
      </Helmet>

      <CardHeader style={{ display: 'flex', justifyContent: 'center' }}>
        {
          hideOrShowProperty(discover, 'profileImage', 'product') && product && product.profileImage && product.profileImage.cropped ? <Box component="div" display='block' className={classNames(classes.productImgContainer, classes.roundedCircle)} style={{ minWidth: '200px', width: '200px', backgroundImage: `url('${product.profileImage.cropped}')` }}></Box>
            : <Box display='block' className={classNames(classes.imgContainer, classes.roundedCircle)} style={{ minWidth: '200px', width: '200px' }}>
              <Skeleton duration={0.7} width={200} height={200} circle={true} />
            </Box>
        }
      </CardHeader>

      <CardBody style={{ padding: 0 }}>
        <div className={classes.cardBodyContainer}>
          {/* <CompanyVerificationBanner /> */}
          {/* nome */}
          {/* TODO: levare qui il CIAO e sviluppare un componente che abbia questo funzionamento */}
          {hideOrShowProperty(discover, 'name', 'product')
            ? <h4 className={classes.cardTitle}>{product && product.name}</h4>
            : null}

          {/* social link */}
           <SocialLinks />
          {/* descrizione */}
          <Box component="div" display='block' className="presentationDescription">
          </Box>
          <Menu />
        </div>
      </CardBody>
    </>
  )
}
