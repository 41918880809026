import React, { useContext } from 'react'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import { makeStyles } from 'tss-react/mui'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import { someLots, getLots, populateLotData } from '../../actions/AppActions'
import { AppContext } from '../../context/AppState'
import { useNavigate } from 'react-router'
import { Typography, useMediaQuery, TextField, InputAdornment } from '@mui/material'
import moment from 'moment'
import {
  DateRange as DateRangeIcon
} from '@mui/icons-material'
import presentationStyle from 'assets/jss/material-kit-pro-react/views/presentationStyle'
import { useTranslation } from 'react-i18next'
// import MaterialTable from 'material-table'
import LogoLoader from 'components/LogoLoaderImage/index'
import DateCalendar from 'components/DateCalendar/DateCalendar'
import Button from 'components/CustomButtons/Button'
import './style.css'
const useStyles = makeStyles()(presentationStyle)
// const openUrl = (url) => {
//   window.open(url, '_blank')
// }
const TraceabilityExpDateTable = () => {
  const { classes } = useStyles()

  const navigate = useNavigate()
  const [openDatePicker, setOpenDatePicker] = React.useState(false)
  const [dateExp, setDateExp] = React.useState(new Date().toISOString())
  const [isLoading, setIsLoading] = React.useState(false)
  const [isThereLots, setIsThereLots] = React.useState(false)
  const [openLotTable, setOpenLotTable] = React.useState(false) // questa variabile serve per gestire il comportamento della tabella dei lotti
  const { state, dispatch } = useContext(AppContext)
  const isSmall = useMediaQuery('(max-width: 600px)')
  const { t } = useTranslation('traceabilityCalendarExpDate')
  // const [lotColumns, setLotColumns] = React.useState([
  //   { title: t('table.lotNumber'), field: 'lotNumber' },
  //   { title: t('table.createdAt'), field: 'createdAt', render: rowData => rowData.expirationDate ? moment(rowData.expirationDate).format('DD/MM/YYYY') : '/' },
  //   // eslint-disable-next-line react/display-name
  //   { title: 'Link', field: 'expirationDate' }
  // ])

  const lotColumns = [
    { title: t('table.lotNumber'), field: 'lotNumber' },
    { title: t('table.createdAt'), field: 'createdAt', render: rowData => rowData.createdAt ? moment(rowData.createdAt).format('DD/MM/YYYY') : '/' }
  ]
  const { product, lots } = state

  // temporary gtin
  const productUuid = product.uuid

  async function clickToOpen () {
    setOpenDatePicker(!openDatePicker)
    setOpenLotTable(false)
  }

  // useEffect(() => {
  //   setLotColumns([{ title: t('table.lotNumber'), field: 'lotNumber' },
  //     { title: t('table.createdAt'), field: 'createdAt', render: rowData => rowData.createdAt ? moment(rowData.createdAt).format('DD/MM/YYYY') : '/' }
  //     // eslint-disable-next-line react/display-name
  //     // { title: 'Link', render: rowData => <Link to={location => `${location.pathname}/lot/${rowData.lotNumber}`}>Ciao</Link> }
  //   ])
  //   console.log('dateExp', dateExp)
  //   return () => {
  //     console.log('cleaned up')
  //   }
  // }, [language])

  // useEffect(() => {
  //   return () => {
  //     console.log('cleaned up')
  //   }
  // }, [])

  const searchLot = async (lotNumber) => {
    if (window.location.pathname.indexOf('lot') === -1) {
      if (window.location.pathname.indexOf('gtin') > -1) {
        navigate(window.location.pathname + `/lot/${lotNumber}`, {
          fromProductPage: true
        })
      }
      if (window.location.pathname.indexOf('/01/') > -1) {
        navigate(window.location.pathname + `/10/${lotNumber}`, {
          fromProductPage: true
        })
      }
    }
    setIsLoading(true)
    try {
      let lots = await getLots(productUuid, lotNumber)

      // Se il lotto esiste, allora popolo i campi e lo mostro, altrimenti mostro il prodotto con schermata di lotto non trovato
      if (Object.entries(lots).length > 0) {
        // const expirationDate = lots.expirationDate
        lots = await populateLotData(lots)
        dispatch({
          type: 'SET_LOT',
          payload: lots
        })
        dispatch({
          type: 'SET_LOT_LOADED',
          payload: true
        })
        dispatch({
          type: 'SET_LOT_NUMBER',
          payload: lots.lotNumber
        })
        // dispatch({
        //   type: 'SET_EXPIRATION_DATE',
        //   payload: expirationDate
        // })
        dispatch({
          type: 'SET_URL_HAS_LOT',
          payload: true
        })
        setIsLoading(false)
      } else {
        dispatch({
          type: 'SET_LOT_NOT_FOUND',
          payload: true
        })
        setIsLoading(false)
      }
    } catch (e) {
      dispatch({
        type: 'SET_LOT_NOT_FOUND',
        payload: true
      })
      setIsLoading(false)
    }
  }

  async function fetchLots (dateExp) {
    try {
      let isThereLots = false
      const tmpDate = new Date(dateExp)
      tmpDate.setHours(0, 0, 0, 0)
      const dayAfterDate = new Date(tmpDate.getTime()) // prende il giorno precedente
      dayAfterDate.setDate(dayAfterDate.getDate() + 1)
      const params = {
        filter: JSON.stringify({ status: 'active', productId: productUuid, expirationDate: { $gte: tmpDate.toISOString(), $lt: dayAfterDate.toISOString() } }),
        fields: 'lotNumber,createdAt'
      }
      const lots = await someLots(productUuid, params)
      // Se il lotto esiste, allora popolo i campi e li mostro, altrimenti mostro il prodotto con schermata di lotto non trovato
      dispatch({
        type: 'SET_ALL_LOTS',
        payload: lots
      })
      if (lots && lots.length > 0) {
        isThereLots = true
      } else {
        isThereLots = false
      }
      setIsThereLots(isThereLots)
      setOpenLotTable(true)
      setIsLoading(false)
    } catch (e) {

    }
  }
  const handleClickAway = () => {
    setOpenDatePicker(false)
  }
  const dateHandler = (dateExp) => {
    setDateExp(dateExp.toISOString())
  }

  const handleClick = async () => {
    setOpenDatePicker(false) // dopo aver cliccato sulla data chiudo il calendar e carico.
  }

  const searchAllLot = async () => {
    setIsLoading(true)
    await fetchLots(dateExp)
  }
  return (
    <>
      <GridContainer direction="column" justify="center" alignItems="center" spacing={2} style={{ width: isSmall ? '100%' : '', margin: isSmall ? 0 : '' }}>
        <GridItem xs={12} md={12} xl={12} style={{ padding: '20px 0' }}>
          <Typography align='center' style={{ display: 'block', padding: isSmall ? 20 : '' }}>{t('message')}</Typography>
        </GridItem>
        <GridItem xs={12} md={6}>
          <TextField
            className={classes.margin}
            value={new Date(dateExp).toLocaleDateString()}
            label={t('table.startDatePlaceholder')}
            onClick={() => clickToOpen()}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <DateRangeIcon />
                </InputAdornment>
              )
            }}>
          </TextField>
        </GridItem>
        <GridItem xs={12} md={6}></GridItem>
        <GridItem xs={12} md={6} zeroMinWidth>
          <Button onClick={() => searchAllLot()} color="ctaButton" style={{ width: '100%' }} >{t('searchButton')}</Button>
        </GridItem>

        {/* <GridItem xs={12} md={12} xl={12} style={{ padding: '20px 0' }}>
          <Typography align='center' variant="p" com style={{ display: 'block', padding: isSmall ? 20 : '' }}>{t('message')}</Typography>
        </GridItem>
        <GridItem xs={12} md={4} xl={4} className="placeHolderContainer">
          <Fab onClick={clickToOpen} style={{ margin: '0 10px' }}>
            <DateRangeIcon />
          </Fab>
          <GridContainer direction="row" justifyContent="center" alignItems="center" style={{ padding: '40px 0 0 0' }}>
            <GridItem xs={6} md={4} xl={4}>
              <TextField style={{ margin: '0 10px' }} value='ciao' disabled id="outlined-basic" label={t('table.startDatePlaceholder')} variant="outlined" />
            </GridItem>
          </GridContainer>
        </GridItem> */}
      </GridContainer>

      <GridContainer className={classes.contentContainerTraceabilityView} style={isSmall ? { paddingLeft: '0px', paddingRight: '0px' } : {}}>

        <GridItem xs={12} md={12} xl={12}>
          {openDatePicker
            ? <div className="customModal show">
              <ClickAwayListener
                onClickAway={handleClickAway}
              >
                <div className="dateRangeContainer">
                  <DateCalendar
                    handleClick={handleClick}
                    onSelectDate={dateHandler}
                  >
                  </DateCalendar>
                </div>
              </ClickAwayListener>
            </div>

            : <div style={{ 'background-color': 'black' }} className="customModal hide">
              <div className="dateRangeContainer">
                <DateCalendar
                  handleClick={handleClick}
                  onSelectDate={dateHandler}
                >
                </DateCalendar>
              </div>
            </div>
          }
        </GridItem>
        {/*
        <GridItem style={{ marginTop: 10 }} xs={12} md={12} xl={12}>
        </GridItem> */}
        {isLoading &&
        <GridItem md={4}>
          <LogoLoader></LogoLoader>
        </GridItem>}
        {openLotTable
          ? <GridItem xs={12} md={12} xl={12}>
            { isThereLots
              ? <div className="certificationsTable showTitle">
                {/* TODO: sostituire material table
                <MaterialTable

                  localization={{
                    pagination: t('table.localization.pagination', { returnObjects: true }),
                    header: t('table.localization.header', { returnObjects: true })
                  }}
                  // columns={isSmall ? lotsMobileColumnsLastProducts : this.state.lotColumns}
                  columns = {lotColumns}
                  data={lots}
                  options={{
                    search: false,
                    actionsColumnIndex: -1,
                    emptyRowsWhenPaging: false,
                    pageSize: 5,
                    pageSizeOptions: [5, 10, 20, 50, 100]
                  }}
                  actions={[(rowData) => {
                    console.log('rowData:::', rowData)
                    return {
                      icon: 'open_in_new',
                      tooltip: t('table.goToPage'),
                      onClick: (event, rowData) => {
                      // console.log('rowData => ', rowData)
                        searchLot(rowData.lotNumber)
                      }
                    }
                  }
                  ]}
                  components={{
                  // eslint-disable-next-line react/display-name
                    Toolbar: () => (
                      <>
                        <Typography variant='h6' align='center'>{t('table.title', { expDate: new Date(dateExp).toLocaleDateString() })}</Typography>
                      </>
                    )
                  }}
                /> */}
              </div>
              : <Typography align='center' style={{ marginBottom: 50, fontFamily: 'Raleway, sans-serif', padding: 10, borderRadius: 4, background: '#c0392b', color: 'white', width: '100%' }}>{t('noBatch')}</Typography>
            }

          </GridItem>
          : null
        }
      </GridContainer>
    </>
  )
}

export default TraceabilityExpDateTable
