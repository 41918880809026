import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'
import useMediaQuery from '@mui/material/useMediaQuery'
import presentationStyle from 'assets/jss/material-kit-pro-react/views/presentationStyle'
import { AppContext } from '../../context/AppState'
import './style.css'

import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'

import { Table, TableContainer, TableHead, TableRow, TableCell, Paper, TableBody, Typography, Box } from '@mui/material'
import Card from 'components/Card/Card'

const useStyles = makeStyles()(presentationStyle)

const AllergenesTable = () => {
  const { classes } = useStyles()
  const isSmall = useMediaQuery('max-height: 600px')
  const { t } = useTranslation('allergenesTable')

  const { state } = useContext(AppContext)
  const { product, sectionActive } = state

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [sectionActive])
  const { allergenes } = product
  // Rimuovo _id altrimenti viene renderizzato come una riga della tabella
  if (allergenes) {
    delete allergenes._id
    delete allergenes.createdAt
    delete allergenes.updatedAt
  }

  return <div className={classes.lotContainer} style={isSmall ? { marginTop: '16px' } : { background: 'white' }}>
    <GridContainer style={{ width: '100%' }} direction="row" justify="center" alignItems="center" spacing={6}>
      <GridItem xs={12} style={isSmall ? { paddingLeft: '2px', paddingRight: '2px' } : {}}>
        {allergenes ? <TableContainer component={Paper}>
          <Table className="allergenesTable">
            <TableHead>
              <TableRow>
                <TableCell>{t('name')}</TableCell>
                <TableCell>{t('product')}</TableCell>
                <TableCell>{t('productionLine')}</TableCell>
                <TableCell>{t('warehousing')}</TableCell>
                <TableCell>{t('crossContamination')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(allergenes).map((allergeneKey, allergeneIndex) => <TableRow key={`allergene-${allergeneIndex}`}>
                <TableCell style={{ fontWeight: 'bold' }}>{t(`allergenes.${allergeneKey}`)}</TableCell>
                <TableCell className={allergenes[allergeneKey].product ? classes.allergenePositiveCell : classes.allergeneNegativeCell}>{allergenes[allergeneKey].product ? t('yes') : t('no')}</TableCell>
                <TableCell className={allergenes[allergeneKey].productionLine ? classes.allergenePositiveCell : classes.allergeneNegativeCell}>{allergenes[allergeneKey].productionLine ? t('yes') : t('no')}</TableCell>
                <TableCell className={allergenes[allergeneKey].warehousing ? classes.allergenePositiveCell : classes.allergeneNegativeCell}>{allergenes[allergeneKey].warehousing ? t('yes') : t('no')}</TableCell>
                <TableCell className={allergenes[allergeneKey].crossContamination ? classes.allergenePositiveCell : classes.allergeneNegativeCell}>{allergenes[allergeneKey].crossContamination ? t('yes') : t('no')}</TableCell>
              </TableRow>)}
            </TableBody>
          </Table>
        </TableContainer> : (
          <Card className={classes.cardContainer}>
            <Box pt={4} pb={4}>
              <Typography variant='body2' component='div' className={classes.cardDescription}>
                Non ci sono allergeni da mostrare
              </Typography>
            </Box>
          </Card>)}
      </GridItem>
    </GridContainer>
  </div>
}

export default AllergenesTable
