import i18n from '../i18n'
import { alog } from 'utils/apioLog'
// VARIABLES
// variabile con la lingua attuale
export let currLang = getCurrLang()
alog('currLang => ', currLang, 'DEBUG', 'info')

// FUNCTIONS
// funzione che mi ritorna la lingua attuale
export function getCurrLang () {
  return i18n.language
}

// funzione che fa cambiare lingua
export function changeLanguage (lang) {
  alog('new lang => ', lang, 'DEBUG', 'info')
  if (!lang) {
    lang = 'it-IT'
  }
  i18n.changeLanguage(lang)
  currLang = getCurrLang()
}

// funzione che ritorna la lingua leggibile
export function returnAbbr (lang) {
  console.log('LANG', lang)
  let abbr = 'Ita'
  if (lang === 'en-GB' || lang === 'en-US' || lang === 'en') {
    abbr = 'Eng'
  } else if (lang === 'fr-FR' || lang === 'fr') {
    abbr = 'Fra'
  }
  return abbr
}

export function returnAbbrTwo (lang) {
  let abbr = 'it'
  if (lang === 'en-GB' || lang === 'en-US' || lang === 'fr-FR') {
    abbr = 'en'
  }
  return abbr
}

// funzione che ritorna la lingua leggibile
export function returnLangName (lang) {
  let name = 'Italiano'
  if (lang === 'en-GB' || lang === 'en-US') {
    name = 'English'
  }
  return name
}
