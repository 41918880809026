import React from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'
import presentationStyle from 'assets/jss/material-kit-pro-react/views/presentationStyle'
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon
} from '@mui/icons-material'
import { Paper, Table, TableContainer, TableHead, TableBody, TableRow, TableCell, IconButton, Typography, Box, Collapse, Link } from '@mui/material'
import BlockchainIcons from 'components/Blockchain/BlockchainIcons'

const useStyles = makeStyles()(presentationStyle)

function Row (props) {
  const { row, glnName, gtinName } = props
  const { t } = useTranslation('xdaiHeader')
  // const preventDefault = (event) => event.preventDefault()
  const [open, setOpen] = React.useState(false)
  const { classes } = useStyles()
  const shouldContainerBlockchainObject = row.blockchain && Object.keys(row.blockchain).length > 0
  return (
    <React.Fragment>
      <TableRow className={classes.rootTable}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          {shouldContainerBlockchainObject &&
          Object.keys(row.blockchain).map(
            (bloc, index) => <Link key={`index_${index}`} target='_blank' href={row.blockchain[bloc][row.blockchain[bloc].length - 1].publicUrl} >{shouldContainerBlockchainObject ? <BlockchainIcons blockchain={bloc} /> : ''}</Link>

            // row.blockchain[bloc][row.blockchain.length - 1].publicUrl
          )
          }

        </TableCell>
        <TableCell align="right" component="th" scope="row">
          {row.name}
        </TableCell>

        <TableCell align="right" component="th" scope="row">
          {t(`eventType.${row.eventType}`)}
        </TableCell>
        <TableCell align="right">
          {t(`bizStep.${row.bizStep}`)}
        </TableCell>
        <TableCell align="right">
          {row.eventTime
            ? moment(row.eventTime).format('DD/MM/YYYY, h:mm:ss a')
            : moment().format('DD/MM/YYYY, h:mm:ss a')
          }
        </TableCell>
        <TableCell align="right">{glnName[row.location]}</TableCell>
        <TableCell align="right">{glnName[row.sourceLocations[0]]}</TableCell>
        <TableCell align="right">{glnName[row.destinationLocations[0]]}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                {t('materials')}
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {t('input')}</TableCell>
                    <TableCell component="th" scope="row">{t('lottoInput')}</TableCell>
                    <TableCell component="th" scope="row" align="right">{t('quantityInput')}</TableCell>
                    <TableCell component="th" scope="row" align="right">UOM</TableCell>
                  </TableRow>
                  {row.inputs.map((e, inputIndex) => (
                    <TableRow key={`inputs_${inputIndex}`}>
                      <TableCell component="th" scope="row">
                        {gtinName[e.gtin]}
                      </TableCell>
                      <TableCell>{e.lotNumber}</TableCell>
                      <TableCell align="right">{e.quantity}</TableCell>
                      <TableCell align="right">
                        {e.uom}
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {t('output')}</TableCell>
                    <TableCell component="th" scope="row">{t('lottoOutput')}</TableCell>
                    <TableCell component="th" scope="row" align="right">{t('quantityOutput')}</TableCell>
                    <TableCell component="th" scope="row" align="right">UOM</TableCell>
                  </TableRow>
                  {row.outputs.map((e, outputIndex) => (
                    <TableRow key={`inputs_${outputIndex}`}>
                      <TableCell component="th" scope="row">
                        {gtinName[e.gtin]}
                      </TableCell>
                      <TableCell>{e.lotNumber}</TableCell>
                      <TableCell align="right">{e.quantity}</TableCell>
                      <TableCell align="right">
                        {e.uom}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

export default function XdaiTable ({ events, glnName, gtinName }) {
  const { t } = useTranslation('xdaiHeader')
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>{t('blockchain')}</TableCell>
            <TableCell align="right">{t('name')}</TableCell>
            <TableCell align="right">{t('eventType.title')}</TableCell>
            <TableCell align="right">{t('bizStep.title')}</TableCell>
            <TableCell align="right">{t('eventTime')}</TableCell>
            <TableCell align="right">{t('location')}</TableCell>
            <TableCell align="right">{t('locationFrom')}</TableCell>
            <TableCell align="right">{t('locationTo')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {events.map((row, index) => (
            <Row key={`events_${index}`} row={row} glnName={glnName} gtinName={gtinName} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
