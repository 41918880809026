import React, { useContext, useEffect, useState } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { makeStyles } from 'tss-react/mui'
import {FilePresent as FilePresentIcon} from '@mui/icons-material';

import {
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  IconButton,
  Paper} from '@mui/material';

// import MaterialTable from 'material-table'
import { useTranslation } from 'react-i18next'

import presentationStyle from 'assets/jss/material-kit-pro-react/views/presentationStyle'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import { AppContext } from '../../context/AppState'
import { hideOrShowProperty } from 'utils/utils'
const useStyles = makeStyles()(presentationStyle)

  // Funzione che scarica il documento
  const downloadDocument = (name, url) => {
    const a = document.createElement('a')
    document.body.appendChild(a)
    a.style = 'display: none'
    a.href = url
    a.target = '_blank'
    a.download = name
    a.click()
    window.URL.revokeObjectURL(url)
  }

// colonne per tabella certificazione
const useCertColumns = () => {
  const { t } = useTranslation('certificationsTable')

  return [
    { title: t('name'), field: 'name' },
    { title: t('description'), field: 'description' },
    { title: t('authority'), field: 'authority' },
    { title: t('protocolNum'), field: 'num' }
  ]
}

const useCertMobileColumns = () => {
  const { t } = useTranslation('certificationsTable')

  return [
    { title: t('name'), field: 'name' },
    { title: t('description'), field: 'description' }
  ]
}

// traduzuone per tabella
const useTableCurrLang = () => {
  const { t } = useTranslation('certificationsTable')
  return {
    toolbar: {
      searchPlaceholder: t('table.toolbar'),
      searchTooltip: t('table.toolbar')
    },
    body: {
      emptyDataSourceMessage: t('table.emptyData')
    },
    pagination: {
      labelRowsSelect: t('table.rows'),
      labelDisplayedRows: t('table.labelRows'),
      firstTooltip: t('table.first'),
      previousTooltip: t('table.previous'),
      nextTooltip: t('table.next'),
      lastTooltip: t('table.last')
    }
  }
}

const CertificationsTable = () => {
  const { classes } = useStyles()
  const isSmall = useMediaQuery('(max-width: 600px)')
  const tableCurrLang = useTableCurrLang()
  const certColumns = useCertColumns()
  const certMobileColumns = useCertMobileColumns()
  const { t } = useTranslation('certificationsTable')
  const { state, dispatch } = useContext(AppContext)

  const { product, sectionActive, discover } = state
  const [certificationsName, setCertificationsName] = useState([])
  console.log("Product certifications:::", product.certifications)
  // funzione che apre modal documenti

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [sectionActive])

  //   [
  //   { title: t('name'), field: 'name' },
  //   { title: t('description'), field: 'description' },
  //   { title: t('authority'), field: 'authority' },
  //   { title: t('protocolNum'), field: 'num' }
  // ]

  useEffect(() => {
    let certificationsName = [t('name'), t('description'), t('authority'), t('protocolNum'),  t('download')]
    // if(product && 
    //   product.certifications && 
    //   Array.isArray(product.certifications) &&
    //   product.certifications.length ) {
    //     certificationsName = Object.keys(product.certifications[0])
    //   }
    setCertificationsName(certificationsName)

  }, [])

  return (
  <div className={classes.cerficationContainer} style={isSmall ? { marginTop: '16px' } : {}}>
        <div style={{width: "100%"}} className="certificationsTable showTitle">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>{t('name')}</TableCell>
                  <TableCell>{t('description')}</TableCell>
                  <TableCell>{t('authority')}</TableCell>
                  <TableCell>{t('protocolNum')}</TableCell>
                  <TableCell>{t('download')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {product.certifications.map((row) => (
                  <TableRow
                  key={row.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell>{row.description}</TableCell>
                    <TableCell>{row.authority}</TableCell>
                    <TableCell>{row.num}</TableCell>
                    <TableCell>{row.documents.map( (doc) => (
                      <IconButton 
                      aria-label="delete"
                      onClick={() => downloadDocument(doc.name, doc.fileUrl)} >
                        <FilePresentIcon />
                      </IconButton>
                    ))}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
    </div>
  )
}

export default CertificationsTable
