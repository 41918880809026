import React, { useContext } from 'react'
import { AppContext } from '../../context/AppState'
import useMediaQuery from '@mui/material/useMediaQuery'

import Button from 'components/CustomButtons/Button.js'
import { Box } from '@mui/material'

const instagramUrl = 'https://www.instagram.com/'
const instagramApp = 'instagram://user?'

const SocialLinks = () => {
  const isSmall = useMediaQuery('(max-width: 600px)')

  const { state } = useContext(AppContext)
  const { sectionActive, company } = state

  const { social, website } = company
  let instagramDesktop
  let instagramMobile
  if (social && social.instagram) {
    instagramDesktop = `${instagramUrl}${social.instagram.split('@')[1]}`
    instagramMobile = `${instagramApp}username=${social.instagram.split('@')[1]}`
  } else {
    instagramDesktop = null
    instagramMobile = null
  }
  return <Box component="div" display={isSmall && (sectionActive !== 1 && sectionActive !== 2) ? 'none' : 'block'}>
    {website && (<Button
      disabled={!(website)}
      href={website || ''}
      target="_blank"
      color="github"
      justIcon
      
    >
      <i className="fas fa-globe" />
    </Button>
    )}
    {(social && social.facebook) && (<Button
      disabled={!(social && social.facebook)}
      href={(social && social.facebook) || ''}
      target="_blank"
      color="facebook"
      justIcon
      
    >
      <i className="fab fa-facebook-f" />
    </Button>
    )}
    {(social && social.linkedin) && (<Button
      disabled={!(social && social.linkedin)}
      href={(social && social.linkedin) || ''}
      target="_blank"
      color="linkedin"
      justIcon
      
    >
      <i className="fab fa-linkedin-in" />
    </Button>
    )}
    {(social && social.twitter) && (
      <Button
        disabled={!(social && social.twitter)}
        href={ (social && social.twitter) || ''}
        target="_blank"
        color="twitter"
        justIcon
        
      >
        <i className="fab fa-twitter" />
      </Button>)
    }

    {(social && social.instagram)
      ? (!isSmall)
        ? (<Button
          disabled={!(social && social.instagram)}
          href={(social && social.instagram && `${instagramDesktop}`) || ''}
          target="_blank"
          color="instagram"
          justIcon
          
        >
          <i className="fab fa-instagram" />
        </Button>)
        : (<Button
          disabled={!(social && social.instagram)}
          href={(social && social.instagram && `${instagramMobile}`) || ''}
          target="_blank"
          color="instagram"
          justIcon
          
        >
          <i className="fab fa-instagram" />
        </Button>)
      : null
    }
  </Box>
}

export default SocialLinks
