import Axios from 'axios'
import configuration from '../configuration.js'
import { alog } from '../utils/apioLog'
const TRUSTY_API = configuration.apiBaseUrl
const TRACEABILITY_TRUSTY_API = configuration.traceabilityApi.uri
const TRACEABILITY_TRUSTY_API_VERSION = configuration.traceabilityApi.versioning
// get che ritorna un prodotto in base al suo uuid o gtin
export const getProduct = async (uuid) => {
  try {
    const product = await Axios.get(`${TRUSTY_API}/public/products/${uuid}`, {
      headers: {
        authorization: `Basic ${configuration.token}`
      }
    })

    alog('new product => ', product.data.data, 'DEBUG', 'info')
    return product.data.data
  } catch (err) {
    throw new Error('Impossibile scaricare i dati del prodotto')
  }
}

// get che ritorna la risorsa discover
export const getDiscovers = async (params) => {
  try {
    const discover = await Axios.get(`${TRUSTY_API}/public/discovers`, {
      params
    })

    alog('new discover => ', discover.data.data, 'DEBUG', 'info')
    return discover.data.data
  } catch (err) {
    throw new Error('Impossibile scaricare i dati discover')
  }
}

// get che ritorna un'azienda in base al companyId
export const getCompany = async (uuid) => {
  try {
    const company = await Axios.get(`${TRUSTY_API}/public/companies/${uuid}`, {
      headers: {
        authorization: `Basic ${configuration.token}`
      }
    })

    return company.data.data
  } catch (err) {
    throw new Error("Impossibile scaricare i dati dell' azienda")
  }
}

export const getFacilities = async (uuid, params = {}) => {
  try {
    const facilities = await Axios.get(`${TRUSTY_API}/public/orgs/${uuid}/facilities`, {
      headers: {
        authorization: `Basic ${configuration.token}`
      },
      params
    })

    return facilities.data.data
  } catch (err) {
    throw new Error('Impossibile scaricare i dati degli stabilimenti')
  }
}

// Funzione che ritorna il lotto in base all' uuid del lotto o al lotNumber
export const getLots = async (productUuid, lotUuid) => {
  const lots = await Axios.get(`${TRUSTY_API}/public/products/${productUuid}/lots/${lotUuid}`)
  alog('lots.data: ', lots.data, 'DEBUG', 'info')
  return lots.data.data
}

// Funzione che ritorna il lotto in base all' uuid del lotto o al lotNumber
export const getLightLots = async (productUuid, lotUuid) => {
  const lots = await Axios.get(`${TRUSTY_API}/public/products/${productUuid}/light-lots/${lotUuid}`)
  alog('lots.data: ', lots.data, 'DEBUG', 'info')
  return lots.data.data
}

// Funzione che ritorna il lotto in base all' uuid del lotto o al lotNumber
export const getTraceabilityEvents = async (productGtin, lotNumber) => {
  const lots = await Axios.get(`${TRACEABILITY_TRUSTY_API}/${TRACEABILITY_TRUSTY_API_VERSION}/public/traceBackward/lot/${productGtin}/${lotNumber}`)
  alog('lots.data: ', lots.data, 'DEBUG', 'info')
  return lots.data.data
}

// NOTE: Funzione che ritorna tutti gli stabilimenti nella catena track and trace
export const getTraceabilityFacilitiesTrackAndTrace = async (productGtin, lotNumber, params) => {
  const facilities = await Axios.get(`${TRACEABILITY_TRUSTY_API}/${TRACEABILITY_TRUSTY_API_VERSION}/public/traceBackward/lot/${productGtin}/${lotNumber}/facilities/all`, {
    params
  })
  alog('facilities.data: ', facilities.data.data, 'DEBUG', 'info')
  return facilities.data.data
}

// NOTE: Funzione che ritorna tutti i prodotti nella catena track and trace
export const getTraceabilityProductsTrackAndTrace = async (productGtin, lotNumber, params) => {
  const products = await Axios.get(`${TRACEABILITY_TRUSTY_API}/${TRACEABILITY_TRUSTY_API_VERSION}/public/traceBackward/lot/${productGtin}/${lotNumber}/products/all`, {
    params
  })
  alog('products.data: ', products.data.data, 'DEBUG', 'info')
  return products.data.data
}

// Funzione che ritorna il lotto in base all' uuid del lotto o al lotNumber
export const getLotsRawMaterial = async (productUuid, lotUuid) => {
  try {
    const lots = await Axios.get(`${TRUSTY_API}/public/products/${productUuid}/lots/${encodeURIComponent(lotUuid)}`, {
      headers: {
        authorization: `Basic ${configuration.token}`
      }
    })
    alog('lots.data: ', lots.data, 'DEBUG', 'info')
    return lots.data.data
  } catch (err) {
    throw new Error('Impossibile scaricare i dati del lotto')
  }
}
// Funzione che ritorna il lotto in base all' uuid del lotto o al lotNumber
export const someLots = async (productUuid, params) => {
  try {
    const lots = await Axios.get(`${TRUSTY_API}/public/products/${productUuid}/lots`, {
      headers: {
        authorization: `Basic ${configuration.token}`
      },
      params
    })
    return lots.data.data
  } catch (err) {
    throw new Error('Impossibile scaricare i dati del lotto')
  }
}

// Funzione che prende un prodotto tramite il suo uuid
const getProdById = async (id) => {
  const response = await Axios.get(`${TRUSTY_API}/public/products/${id}`, {
    params: {
      // filter: JSON.stringify({ status: { $ne: 'deleted' } })
      filter: JSON.stringify({ $or: [{ status: 'deleted' }] })
    },
    headers: {
      authorization: `Basic ${configuration.token}`
    }
  })

  return response && response.data && response.data.data ? response.data.data : []
}

// Funzione che prende le informazioni mancanti riguardanti facilities e prodotti e le inseriscono nel lotto
export const populateLotData = async (lots) => {
  if (Array.isArray(lots.traceability)) {
    for (const index in lots.traceability) {
      const track = lots.traceability[index]
      // prprietà aggiunta per la visualizzazione delle card nella scheda tracciabilità
      track.activeSection = { card: index, showMap: false }

      if (Array.isArray(track.inputs)) {
        for (const input of track.inputs) {
          input.inputData = await getProdById(input.productId)
        }
      }

      if (Array.isArray(track.outputs)) {
        for (const output of track.outputs) {
          output.outputData = await getProdById(output.productId)
        }
      }
    }
  }
  return lots
}

// Funzione che prende le informazioni mancanti riguardanti facilities e prodotti e le inseriscono nel lotto
export const populateProductData = async (product) => {
  // FIXME: viene fatta una chiamata per ogni input. Quindi vengono fatte più richieste verso la stessa risorsa quando gli input sono uguali.
  if (Array.isArray(product.traceability)) {
    for (const index in product.traceability) {
      const track = product.traceability[index]
      // prprietà aggiunta per la visualizzazione delle card nella scheda tracciabilità
      track.activeSection = { card: index, showMap: false }

      if (Array.isArray(track.inputs)) {
        for (const input of track.inputs) {
          input.inputData = await getProdById(input.productId)
        }
      }

      if (Array.isArray(track.outputs)) {
        for (const output of track.outputs) {
          output.outputData = await getProdById(output.productId)
        }
      }
    }
  }
  return product
}

// funzione che prende le informazioni relative alle operazioni di opentimestamps, partendo dal parametro 'filename' che corrisponde all'url del file ots del lotto esaminato
export const getOpentimestampsInfo = async (filename) => {
  try {
    const opentimestampsResponse = await Axios.get(`${configuration.apiBaseUrl}/public/opentimestamps/info?filename=${filename}`, {
      headers: {
        authorization: `Basic ${configuration.token}`
      }
    })

    return opentimestampsResponse.data.data
  } catch (err) {
    alog('Error: ', err, 'ERROR', 'error')
  }
}

export const sendRequestBuyNow = async (name, lastname, email, companyId, productId) => {
  try {
    const response = await Axios.post(`${configuration.apiBaseUrl}/contacts/consumers`, {
      name,
      lastname,
      email,
      companyId,
      productId
    },
    {
      headers: {
        contentType: 'application/json'
      }
    })
    alog('Response status:', response, 'DEBUG', 'info')
    return response.status
  } catch (error) {
    alog('Error: ', error, 'ERROR', 'error')
    return error.response.status
  }
}
