import React from 'react'
// import { makeStyles } from 'tss-react/mui'

import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

// const useStyles = makeStyles({
//   avatar: {
//     backgroundColor: blue[100],
//     color: blue[600]
//   }
// })

function InfoFromProductor (props) {
  // const { classes } = useStyles()
  const { t } = useTranslation('infoFromProductor')
  const { onClose, open } = props

  return (
    <Dialog onClose={onClose} open={open} >
      <DialogTitle>{t('title')}</DialogTitle>
      <DialogContent>
        {props.type === '0'
          ? <DialogContentText >
            Attenzione: a differenza dell'altro, questo dato è valido solo ai fini del test di collaudo tecnico.
            <br />[ENG] Attention: unlike the other one, this data is valid only for the purpose of technical acceptance testing.
          </DialogContentText>
          : <DialogContentText >
            {t('noContent')}
          </DialogContentText>
        }

      </DialogContent>
    </Dialog>
  )
}
InfoFromProductor.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired
}
export default InfoFromProductor
