import React, { useContext, useCallback, useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { Box, Typography, Link } from '@mui/material'
import { LocationOn as LocationOnIcon } from '@mui/icons-material'
import presentationStyle from 'assets/jss/material-kit-pro-react/views/presentationStyle'
import { getFacilities } from 'actions/AppActions'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
// import SwapCallsIcon from '@mui/icons-material/SwapCalls'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Map from 'components/Map/Map'
import Button from 'components/CustomButtons/Button'
import Grid from '@mui/material/Grid'
import { useTranslation } from 'react-i18next'
import { AppContext } from '../../context/AppState'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import { hideOrShowProperty } from 'utils/utils'
const useStyles = makeStyles()(presentationStyle)

// funzione che ritorna il corpo della tabella degli input/output

const inputsOutputsTableDoby = (typeOfElement, track, inputs = [], outputs = []) => {
  const array = [...Array(!outputs ? inputs.length : inputs.length >= outputs.length ? inputs.length : outputs.length)]
  const finalArray = []
  array.forEach((el, i) => {
    const newLine = []
    if (inputs && inputs[i] && track.inputs[i]) {
      newLine[0] = <Grid key={`${track.name}-input-grid-${i.toString()}`}><b>{`${inputs[i].inputData.name}`}</b><br></br>
      </Grid>
    } else {
      newLine[0] = ''
    }
    if (outputs && outputs[i] && track.outputs[i]) {
      newLine[1] = <Grid key={`${track.name}-output-grid-${i.toString()}`}><b key={i.toString()}>{`${outputs[i].outputData.name} `}</b><br></br>
      </Grid>
    } else {
      newLine[1] = ''
    }

    finalArray.push(newLine)
  })

  return finalArray.map((elem, i) => {
    if (typeOfElement === 'list') {
      if (i > 1) {
        return null
      }
    }
    return (
      <div key={`${track.name}-elem-div-${i.toString()}`}>
        <div key={`${track.name}-input-div-${i.toString()}`} className="raleway inputList">
          {elem[0]}
        </div>
        {outputs && outputs.length > 0 &&
          <div key={`${track.name}-output-div-${i.toString()}`} className="raleway inputList">
            {elem[1]}
          </div>
        }
      </div>
    )
  })
}
/*
    come devo avere l'oggetto Location:
    {
        name: ''
        uuid: ''
        lat: ''
        long: ''
    }
*/
const ProductTraceabilityCard = (props) => {
  const { classes } = useStyles()
  // const isSmall = useMediaQuery('(max-width: 600px)')
  const { t } = useTranslation('traceabilityCard')

  const { track, index, organizationId } = props

  const [truncatedDescription, setTruncatedDescription] = React.useState()
  const [typeOfUi, setTypeOfUi] = React.useState('list')

  const [facilities, setFacilities] = useState([])
  const [facilityUiidNameMap, setFacilityUiidNameMap] = useState({})
  const [openMaterials, setOpenMaterials] = React.useState(false)

  const handleClickOpenMaterials = () => {
    setOpenMaterials(true)
    setTypeOfUi('modal')
  }

  const handleCloseMaterials = () => {
    setOpenMaterials(false)
    setTypeOfUi('list')
  }

  // stato globale dell'app estrapolato dal context
  const { state, dispatch } = useContext(AppContext)

  const { product, discover } = state

  const facilityParams = {
    limit: Number.MAX_SAFE_INTEGER,
    fields: 'name,gln,uuid,geolocation'
  }
  // NOTE: useEffect utile per recuperare le facilies che dovrò mostrare nel tabela
  useEffect(() => {
    // eslint-disable-next-line no-inner-declarations
    async function fetchFacilities () {
      const facilities = await getFacilities(organizationId, facilityParams)
      setFacilities(facilities)
      const mapFac = {}
      facilities.forEach(x => {
        mapFac[x.uuid] = x
      })
      setFacilityUiidNameMap(mapFac)
    }
    fetchFacilities()
  }, [])

  // funzione che mostra/nasconde la mappa di una card nella tracciabilità
  const toggleMap = (cardIndex, showOnly = false) => {
    const prod = Object.assign({}, product)
    const traceability = prod.traceability
    const { activeSection, ...track } = traceability[cardIndex]
    traceability.splice(cardIndex, 1, { activeSection: { card: cardIndex, showMap: showOnly ? true : !activeSection.showMap }, ...track })
    prod.traceability = traceability
    dispatch({
      type: 'SET_PRODUCT',
      payload: prod
    })
  }

  // funzione che apre modal descrizione traccia
  const handleOpenTrackDesc = (description, name, imgUrl) => {
    dispatch({
      type: 'SET_TRACK_DESC',
      payload: { description, name, imgUrl }
    })
    dispatch({
      type: 'SET_OPEN_TRACK_DESC',
      payload: true
    })
  }

  // Uso una funzione di callback perchè riusciamo a capire
  // le dimensioni del nodo anche se viene renderizzato successivamente
  const lengthRef = useCallback(node => {
    if (node !== null) {
      dispatch({
        type: 'SET_PRODUCT',
        payload: product
      })
    }
  }, [product, dispatch])

  // funzione che torna il bottone di mostra di più per le descrizioni troncate
  const addShowMoreButton = (id, description, name, imgUrl) => {
    const elem = document.getElementById(id)
    if (elem) {
      return (
        <Box display="flex" justifyContent="flex-start" alignItems="center" style={{ width: '100%', marginTop: 30 }}>
          <Link onClick={() => handleOpenTrackDesc(description, name, imgUrl)} style={{ cursor: 'pointer', color: ' #3C4858' }}>
            <AddCircleOutlineIcon />
          </Link>
        </Box>
      )
    }
  }

  useEffect(() => {
    if (track.media.length === 0) {
      toggleMap(index, true)
    }

    if (track.description.length > 100) {
      const sortDescription = track.description.substring(0, 100)
      setTruncatedDescription(sortDescription + '...')
    } else {
      setTruncatedDescription(track.description)
    }

    // eslint-disable-next-line
  }, [])

  // NOTE: potrebbe esserci il caso che sia locationTo che locationFrom che location siano settate. In quel caso viene graficato sulla mappa location.
  const handleMapAndText = (track, facilityUiidNameMap, index) => {
    if (track.activeSection.showMap) {
      if (track.location && facilityUiidNameMap[track.location]) {
        return <div className={classes.imgContainer} onMouseLeave={() => track.media.length > 0 ? toggleMap(index) : null}>
          <Map isHeader={true} lat={facilityUiidNameMap[track.location].geolocation.lat} lng={facilityUiidNameMap[track.location].geolocation.lng} />
        </div>
      }
      if (track.locationFrom && facilityUiidNameMap[track.locationFrom[0]] &&
        track.locationTo && facilityUiidNameMap[track.locationTo[0]]) {
        return <div className={classes.imgContainer} onMouseLeave={() => track.media.length > 0 ? toggleMap(index) : null}>
          <Map isHeader={true} zoom={14} positions={[[facilityUiidNameMap[track.locationFrom[0]].geolocation.lat, facilityUiidNameMap[track.locationFrom[0]].geolocation.lng], [facilityUiidNameMap[track.locationTo[0]].geolocation.lat, facilityUiidNameMap[track.locationTo[0]].geolocation.lng]]} lat={facilityUiidNameMap[track.locationTo[0]].geolocation.lat} lng={facilityUiidNameMap[track.locationTo[0]].geolocation.lng} />
        </div>
      }
    } else {
      return <> <div onMouseEnter={() => toggleMap(index)} className={`${classes.imgContainer} noImage`} style={track.media.length > 0 ? { backgroundImage: `url('${track.media[0].cropped}')` } : {}}>
      </div>
      </>
    }
  }
  /* MAPPA ed Immagini */
  return <Grid item xs={12} md={12} className={classes.mediaAndMap}>
    <GridContainer direction="row" justify="flex-start" style={{ padding: 0 }}>
      <GridItem className={classes.mediaAndMapFirstGridItem} xs={12} sm={6}>
        <GridContainer
          direction="column"
          alignItems="stretch"
          justify="flex-start"
          className={classes.mediaAndMapSecondGridContainer}>
          <GridItem
            className={classes.mediaAndMapSecondGridItem}
            key={`${track.name}-${index}-grid-item-sx-1`} style={{
              backgroundImage: track.media.length > 0 ? `url('${track.media[0].cropped}')` : '#fafafa'
            }} xs={12} sm={12}>
            <div className={classes.imgContainer} style={track.media.length > 0 ? { backgroundImage: `url('${track.media[0].cropped}')` } : {}}>
              <GridItem style={{ width: '100%', height: '100%', padding: 0 }} xs={12} sm={12}>
                {hideOrShowProperty(discover, 'allLocations', 'lot') && handleMapAndText(track, facilityUiidNameMap, index)
                }
              </GridItem>
              <GridItem style={{ marginTop: '-60px', marginLeft: '20px', padding: 0, width: '100%', textAlign: 'left' }} xs={7} sm={12}>
                {/* <GridItem xs={7} sm={12}> */}
                <Button
                  onMouseEnter={() => track.media.length > 0 ? toggleMap(index) : null}
                  style={{ backgroundColor: '#00A4AE', zIndex: 1000 }}
                  round
                  justIcon
                  onClick={() => track.media.length > 0 ? toggleMap(index) : null}
                >
                  <i title={track.activeSection.showMap ? t('showImage') : t('showMap')} className={track.activeSection.showMap ? 'fas fa-image' : 'fas fa-map-marker-alt'} />
                </Button>
              </GridItem>

            </div>

          </GridItem>
        </GridContainer>
      </GridItem>

      <GridItem sm={6} key={`${track.name}-${index}-grid-item-dx`}>

        {/* NOME FASE */}
        <span className={`${classes.nameTraceability}`}>{t('phase')} {index + 1}</span>
        <h4 className={`${classes.cardTitleDesktop}`}>{track.name}</h4>
        <Typography ref={lengthRef} id={`${track.name}-${index}`} variant="body2" style={{ marginBottom: 8, marginTop: 20, width: '100%', textAlign: 'left', color: '#000' }} className={classes.cardDescription}>
          {truncatedDescription}
        </Typography>
        {track.description.length > 100
          ? addShowMoreButton(`${track.name}-${index}`, track.description, track.name, track.media.length > 0 ? track.media[0].cropped : null)
          : null
        }
        {/* LOCATION  */}
        {track.location
          ? <GridContainer className="metaContainer">
            {hideOrShowProperty(discover, 'allLocations', 'lot') && <>
              <GridItem md={4} lg={4}></GridItem>
              <GridItem md={2} lg={4} className="metaInnerContainer metaInnerContainerCenter">
                <div key={`${track.name}-${index}-div-3`} style={{ paddingTop: 5 }}>
                  <LocationOnIcon style={{ color: '#3C4858' }} />
                </div>
                <div key={`${track.name}-${index}-div-4`} style={{ paddingTop: 15, textTransform: 'uppercase', textAlign: 'center' }}>
                  <span style={{ textTransform: 'uppercase' }}>
                    {facilityUiidNameMap[track.location] && facilityUiidNameMap[track.location].name}
                  </span>
                </div>
              </GridItem>
            </>
            }

          </GridContainer>
          : track.locationFrom &&
          Array.isArray(track.locationFrom) &&
          track.locationFrom.length > 0 &&
          track.locationTo &&
          Array.isArray(track.locationTo) &&
          track.locationTo.length > 0
            ? <GridContainer className="metaContainer">
              <GridItem md={5} lg={4} className="metaInnerContainer">

                {hideOrShowProperty(discover, 'allLocations', 'lot') && <>
                  <div key={`${track.name}-${index}-div-3`} style={{ paddingTop: 5 }}>
                    <LocationOnIcon style={{ color: '#3C4858' }} />
                  </div>
                  <div style={{ textAlign: 'center', paddingTop: 15 }}>
                    <span style={{ textTransform: 'uppercase' }}>
                      {facilityUiidNameMap[track.locationFrom[0]] && facilityUiidNameMap[track.locationFrom[0]].name}
                    </span>
                  </div>
                </>}
              </GridItem>

              <GridItem md={2} lg={4} className="metaInnerContainer">
                <div className="locationIcon" style={{ paddingTop: 10 }}>
                  <LocalShippingIcon style={{ color: '#3C4858' }} />
                </div>
              </GridItem>
              <GridItem md={5} lg={4} className="metaInnerContainer">
                {hideOrShowProperty(discover, 'allLocations', 'lot') && <>
                  <div key={`${track.name}-${index}-div-3`} style={{ paddingTop: 5 }}>
                    <LocationOnIcon style={{ color: '#3C4858' }} />
                  </div>
                  <div style={{ textAlign: 'center', paddingTop: 15 }}>
                    <span style={{ textTransform: 'uppercase' }}>
                      {facilityUiidNameMap[track.locationTo[0]] && facilityUiidNameMap[track.locationTo[0]].name}
                    </span>
                  </div>
                </>
                }
              </GridItem>

            </GridContainer>

            : ''
        }
        {/* MATERIALI */}
        <GridContainer className="materialsContainer">
          {track.outputs && track.outputs.length > 0
            ? <>
              {hideOrShowProperty(discover, 'allInputs', 'lot') && <GridItem md={5} lg={4} className="metaInnerContainer">
                <Typography style={{ textAlign: 'center', width: '100%' }} className="fredoka">
                  {t('usedMaterials')}
                </Typography>
                {inputsOutputsTableDoby(typeOfUi, track, track.inputs)}
                {track.inputs.length > 2 &&
                <div>
                  <span style={{
                    cursor: 'pointer',
                    marginTop: 30,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    textDecoration: 'underline'
                  }}
                  color="primary" onClick={handleClickOpenMaterials}>
                    <b>{t('other')}</b>
                  </span>
                  <Dialog
                    maxWidth="lg"
                    className="materialsDialog"
                    open={openMaterials}
                    onClose={handleCloseMaterials}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      <Typography variant="h4" className="fredoka">{t('usedMaterials')}</Typography>
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        {/* {inputsOutputsTableDoby(typeOfUi, track, track.inputs)} */}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleCloseMaterials} color="generalButton" autoFocus>
                        Chiudi
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
                }
              </GridItem>
              }
              {hideOrShowProperty(discover, 'allOutputs', 'lot') &&
              <GridItem md={5} lg={4} className="metaInnerContainer">
                <Typography style={{ textAlign: 'center', width: '100%' }} className="fredoka">
                  {t('producedMaterials')}
                </Typography>
                {inputsOutputsTableDoby(typeOfUi, track, [], track.outputs)}
                {track.outputs.length > 2 &&
                  <div>
                    <span style={{
                      cursor: 'pointer',
                      marginTop: 30,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      textDecoration: 'underline'
                    }}
                    color="primary" onClick={handleClickOpenMaterials}>
                      <b>{t('other')}</b>
                    </span>

                    <Dialog
                      className="materialsDialog"
                      maxWidth="lg"
                      open={openMaterials}
                      onClose={handleCloseMaterials}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                        <Typography variant="h4" className="fredoka">{t('producedMaterials')}</Typography>
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          {inputsOutputsTableDoby(typeOfUi, track, [], track.outputs)}
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleCloseMaterials} color="generalButton" autoFocus>
                          Chiudi
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                }
              </GridItem>
              }
            </>
            : <>
              {hideOrShowProperty(discover, 'allInputs', 'lot') && <GridItem sm={12} className="metaInnerContainer">
                <Typography style={{ textAlign: 'center', width: '100%' }} className="fredoka">
                  {t('usedMaterials')}
                </Typography>
                {inputsOutputsTableDoby(typeOfUi, track, track.inputs)}
                {track.inputs.length > 2 &&
                <div>
                  <span style={{
                    cursor: 'pointer',
                    marginTop: 30,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textDecoration: 'underline'
                  }} color="primary" onClick={handleClickOpenMaterials}>
                    <b>{t('other')}</b>
                  </span>
                  <Dialog
                    className="materialsDialog"
                    maxWidth="lg"
                    open={openMaterials}
                    onClose={handleCloseMaterials}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle class="fredoka">
                      <Typography variant="h4" className="fredoka">{t('usedMaterials')}</Typography>
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        {inputsOutputsTableDoby(typeOfUi, track, track.inputs)}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleCloseMaterials} color="generalButton" autoFocus>
                        Chiudi
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
                }
              </GridItem>
              }
            </>
          }
        </GridContainer>

      </GridItem>
    </GridContainer>
  </Grid>
}

export default ProductTraceabilityCard
