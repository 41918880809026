import React, { useContext, useEffect } from 'react'
import { CardContent, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useTranslation } from 'react-i18next'
import {
  Room as RoomIcon,
  Email as EmailIcon,
  Web as WebIcon,
  Store as StoreIcon,
  Call as CallIcon
} from '@mui/icons-material'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Card from 'components/Card/Card'
import Map from 'components/Map/Map'
import { AppContext } from '../../context/AppState'
import CertificationsTable from './CertificationsTable'

import presentationStyle from 'assets/jss/material-kit-pro-react/views/presentationStyle'
import Link from '@mui/material/Link'

const useStyles = makeStyles()(presentationStyle)

const CompanyInfoMobile = () => {
  const { classes } = useStyles()
  const { t } = useTranslation('companyInfo')
  const { state } = useContext(AppContext)
  const { company, sectionActive } = state

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [sectionActive])

  const extractProductionKey = (attributeKey) => {
    let translatedString = ''
    if (attributeKey.includes('productions')) {
      const productionsArray = attributeKey.split(' ')
      const [prodString, ...rest] = productionsArray
      translatedString = [t(prodString), ...rest].join(' ')
    } else {
      translatedString = t(attributeKey)
    }

    return translatedString
  }

  return <> <GridContainer style={{ padding: 0, marginTop: '16px' }} className={classes.contentContainer} spacing={4}>
    <GridItem xs={12} style={{ paddingTop: '26px' }}>
      <Card style={{ padding: '20px', marginTop: 10, marginBottom: 20 }}>
        <CardContent style={{ padding: 0 }}>

          <GridContainer direction="row" justify="center" alignItems="center">
            <GridItem xs={12} md={6}>
              <h1 className={classes.cardTitleSmall} style={{ marginBottom: 20 }}>{t('titleInfo')}</h1>
              {company && company.address &&
              <GridContainer direction="column" justify="center" alignItems="center" style={{ marginBottom: 15 }}>
                <RoomIcon />
                <span className={classes.boldText}>{`${t('address')} `}</span>
                <Typography variant="body1" component="p" align="center">{`${company && company.address && company.address.street}`}</Typography>
                <span className={classes.boldText}>{`${t('city')} `}</span>
                <Typography variant="body1" component="p" align="center">{`${company && company.address && company.address.city}`}</Typography>
              </GridContainer>
              }
              {company && company.email
                ? <GridContainer direction="column" justify="center" alignItems="center" style={{ marginBottom: 15 }}>
                  <EmailIcon />
                  <span className={classes.boldText}>{`${t('email')} `}</span>
                  <Link color="inherit" href={`mailto:${company.email}`}>{company.email}</Link>
                </GridContainer>
                : ''}
              {company && company.website
                ? <GridContainer direction="column" justify="center" alignItems="center" style={{ marginBottom: 15 }}>
                  <WebIcon />
                  <span className={classes.boldText}>{`${t('website')} `}</span>
                  <Link color="inherit" href={`${company.website}`}>{company.website}</Link>
                </GridContainer>
                : ''}
              {company && company.ecommerce
                ? <GridContainer direction="column" justify="center" alignItems="center" style={{ marginBottom: 15 }}>
                  <StoreIcon />
                  <span className={classes.boldText}>{`${t('ecommerce')} `}</span>
                  <Link color="inherit" href={`${company.ecommerce}`}>{company.ecommerce}</Link>
                </GridContainer>
                : ''}
              {company && company.attributes ? company.attributes.map((attribute, index) => <p key={`attribute-${index}`}><span className={classes.boldText}>{`${extractProductionKey(attribute.name)}: `}</span>{attribute.value} {attribute.name === 'surface' ? t('surfaceUnit') : ''}</p>) : ''}
            </GridItem>
            {company && company.phoneNumber
              ? <GridContainer direction="column" justify="center" alignItems="center" style={{ marginBottom: 15, width: '100%' }}>
                <CallIcon />
                <span className={classes.boldText}>{`${t('phoneNumber')} `}</span>
                <Link color="inherit" href={`tel:${company.phoneNumber}`}>{company.phoneNumber}</Link>
              </GridContainer>
              : ''}
            {company && company.mobileNumber
              ? <GridContainer direction="column" justify="center" alignItems="center" style={{ marginBottom: 15, width: '100%' }}>
                <CallIcon />
                <span className={classes.boldText}>{`${t('mobileNumber')} `}</span>
                <Link color="inherit" href={`tel:${company.mobileNumber}`}>{company.mobileNumber}</Link>
              </GridContainer>
              : ''}

            {company && company.geolocation && company.geolocation.lat && company.geolocation.lng
              ? <div className={classes.companyImgContainerMobile}>
                <Map isHeader={true} lat={String(company.geolocation.lat)} lng={String(company.geolocation.lng)} />
              </div>
              : ''}

          </GridContainer>
        </CardContent>
      </Card>
    </GridItem>
  </GridContainer>
  <GridContainer>
    <GridItem>
      <CertificationsTable />
    </GridItem>
  </GridContainer>
  </>
}

export default CompanyInfoMobile
