import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from 'tss-react/mui'
import Button from '@mui/material/Button'
import moment from 'moment'
import { getLotsRawMaterial } from '../../actions/AppActions'
import { alog } from 'utils/apioLog'
import { Dialog, Link, DialogTitle, DialogContent, DialogActions } from '@mui/material'
// import classes from '*.module.css'
import { useTranslation } from 'react-i18next'
import { AppContext } from '../../context/AppState'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import TodayIcon from '@mui/icons-material/Today'
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation'
import SwapCallsIcon from '@mui/icons-material/SwapCalls'
import RoomIcon from '@mui/icons-material/Room'
// import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import ForwardIcon from '@mui/icons-material/Forward'

const useStyles = makeStyles()(() => ({
  readMore: {
    padding: 0,
    marginBottom: 15,
    marginTop: 10,
    color: '#00A4AE',
    textTransform: 'capitalize'
  },
  closeButton: {
    background: '#3C4858',
    // '&:hover': {
    //   background: '#3C4858'
    // },
    color: 'white'
  },
  goToButton: {
    background: '#00A4AE',
    // '&:hover': {
    //   background: '#00A4AE'
    // },
    color: 'white'
  }
}))

const trackObservationShipping = (traceability) => {
  const step = traceability.find(track => track.eventType === 'observation_shipping')
  if (step) {
    return step
  } else {
    return traceability[traceability.length - 1]
  }
}
function SimpleDialog (props) {
  const { onClose, selectedValue, open, input } = props

  async function fetchLots () {
    try {
      const rawMaterial = await getLotsRawMaterial(props.input.productId, props.input.lotNumber)
      // Se il lotto esiste, allora popolo i campi e li mostro, altrimenti mostro il prodotto con schermata di lotto non trovato
      const shippingTrack = trackObservationShipping(rawMaterial.traceability)
      if (Object.entries(rawMaterial).length > 0) {
        // rawMaterial = await populateLotData(rawMaterial)
        dispatch({
          type: 'SET_RAWMATERIAL',
          payload: rawMaterial
        })
        dispatch({
          type: 'SET_SHIPPINGTRACK',
          payload: shippingTrack
        })
        dispatch({
          type: 'SET_INFORMATIONLOADED',
          payload: true
        })
      } else {
        dispatch({
          type: 'SET_LOT_NOT_FOUND',
          payload: true
        })
      }
    } catch (e) {
      dispatch({
        type: 'SET_LOT_NOT_FOUND',
        payload: true
      })
    }
  }

  const { classes } = useStyles()
  alog('props.input', props.input, 'DEBUG', 'info')
  const { state, dispatch } = useContext(AppContext)
  const { rawMaterial, shippingTrack } = state

  const { t } = useTranslation('relatedDialog')

  const useFetchData = () => {
    useEffect(() => {
      fetchLots()
    }, [])
  }

  // chiamata all'hook che fa il fetch dei dati
  useFetchData()
  const handleClose = () => {
    onClose(selectedValue)
  }

  if (rawMaterial !== null && shippingTrack !== null) {
    return (

      <Dialog fullScreen onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
        <DialogTitle className='fredoka' id="simple-dialog-title">{shippingTrack.name}</DialogTitle>
        <DialogContent dividers={true} style={{ paddingLeft: 20, paddingRight: 20 }}>
          {/* DESCRIZIONE */}
          <GridContainer style={{ width: '100%', margin: '10px 0' }}>
            <GridItem xs={12} style={{ marginBottom: 40 }}>
              {shippingTrack.description}
            </GridItem>
          </GridContainer>
          {/* LUOGO */}
          {shippingTrack.location
            ? <GridContainer style={{ width: '100%', margin: '10px 0', display: 'flex', justifyContent: 'center' }}>
              <h5 className="fredoka" style={{ textAlign: 'center' }}>{t('facility')}</h5>
              <h6 style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {/* <RoomIcon style={{ marginBottom: 10 }}/> */}
                <b>{shippingTrack.location.name}</b>
              </h6>
            </GridContainer>
            : shippingTrack.locationFrom && Array.isArray(shippingTrack.locationFrom) && shippingTrack.locationFrom.length > 0 && shippingTrack.locationTo && Array.isArray(shippingTrack.locationTo) && shippingTrack.locationTo.length > 0
              ? <>
                <GridContainer style={{ display: 'block', width: '100%', margin: '10px 0' }}>
                  <h5 className="fredoka" style={{ textAlign: 'center' }}>{t('facilities')}</h5>
                  <div style={{ width: '50%', float: 'left' }}>
                    <h6 style={{
                      textAlign: 'center',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      padding: '0 10px'
                    }}
                    >
                      <RoomIcon style={{ marginBottom: 10 }} />
                      {t('from')} <br></br> {shippingTrack.locationFrom[0].name}
                    </h6>
                  </div>
                  <div style={{ width: '50%', float: 'left' }}>
                    <h6 style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '0 10px' }}>
                      <SwapCallsIcon style={{ marginBottom: 10 }} />
                      {t('to')} <br></br>{shippingTrack.locationTo[0].name}
                    </h6>
                  </div>
                </GridContainer>
                <GridContainer style={{ width: '100%', margin: '10px 0' }}>
                  <div className="lineContainer">
                    <span className="dot first"></span>
                    <span className="line"></span>
                    <span className="dot last"></span>
                  </div>
                </GridContainer>
              </>
              : ''
          }

          {/* DATE */}
          <GridContainer style={{ width: '100%', margin: '10px 0', display: 'block' }}>
            <h5 className="fredoka" style={{ textAlign: 'center' }}>{t('workFlow')}</h5>
          </GridContainer>
          <GridContainer style={{ width: '100%', display: 'flex', alignItems: 'center', margin: '10px 0' }}>
            <GridItem className={classes.dateTraceabilityMobile} xs={5} md={5}>
              <h6 className={classes.dataTraceabilityMobileText} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                <TodayIcon />
                {shippingTrack.startedAt ? moment(shippingTrack.startedAt).format('DD/MM/YYYY') : ''}
              </h6>
            </GridItem>
            <GridItem className={classes.dateTraceabilityMobile} xs={2} md={2}>
              <ForwardIcon />
            </GridItem>
            <GridItem className={classes.dateTraceabilityMobile} xs={5} md={5}>
              <h6 className={classes.dataTraceabilityMobileText} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                <InsertInvitationIcon />
                {shippingTrack.completedAt ? moment(shippingTrack.completedAt).format('DD/MM/YYYY') : ''}
              </h6>
            </GridItem>
          </GridContainer>
          <GridContainer display='flex' justify="center" style={{ margin: '40px 0 0 0' }}>
            <Button className={classes.goToButton} color="ctaButton">
              <Link href={`${window.location.origin}/${input.link}`} color='inherit' target='_blank' >
                CIAO
              </Link>
            </Button>
          </GridContainer>
        </DialogContent>
        <DialogActions>
          <Button className={classes.closeButton} onClick={handleClose} color="generalButton">
            {t('closeButton')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  } else {
    return null
  }
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
  input: PropTypes.object,
  track: PropTypes.string
}

export default function SimpleDialogDemo (props) {
  const { classes } = useStyles()
  const { t } = useTranslation('traceabilityMobileCard')
  const [open, setOpen] = React.useState(false)

  const { input } = props
  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = (value) => {
    setOpen(false)
  }

  return (
    <>
      {/* <Button
        className={classes.readMore}
        onClick={handleClickOpen}
      >
         
        {t('showMore')}
      </Button>
      {open && <SimpleDialog
        input= {input}
        open={open}
        onClose={handleClose} />
      } */}
      <Link href={`${window.location.origin}/${input.link}`} color='inherit' target='_blank' >
      <br />{t('showMore')}  
      </Link>
    </>
  )
}
