export const hideOrShowProperty = (discover, property, type) => {
  if (discover && Object.keys(discover).length > 0) {
    if (!discover.metadata.visibility[type][property]) {
      if (discover.metadata.visibility[type][property] === undefined || discover.metadata.visibility[type][property] === null) { return true }
    }
    return !!discover.metadata.visibility[type][property]
  } else {
    return true
  }
}
